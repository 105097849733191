import { Message, Modal, Spin, Tabs } from "@arco-design/web-react";
import { IconFindReplace, IconHistory, IconImage, IconList, IconSelectAll } from "@arco-design/web-react/icon";
import React, {useState} from "react";
import { EditHistoryTab } from "./historyTab";
import { MetadataTab } from "./metadataTab";
import { OverallTab } from "./overallTab";
import { TextContentTab } from "./textContentTab";
import { ImagesTab } from "./imagesTab";
import { usePDFDocumentStore } from "../../../state/documents";



export function DocumentDetailModal(props) {

  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState(null);

  const documentStore = usePDFDocumentStore();

  documentStore.retrieve(props.uuid).then((document) => {
    if (document !== null && document !== undefined) {
      setLoading(false);
      setDocument(document);
    }
  });

  return (
    <Modal
      title={
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <h2>Document Check Results</h2>
        </div>
      }
      style={{width: "75%"}}
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={props.onCancel}
      hideCancel={true}
    >
      {loading ? <Spin/> : <Tabs defaultActiveTab='1'>
        <Tabs.TabPane key="1" title={<span><IconSelectAll style={{ marginRight: 6 }} />Results Overview</span>}>
          <OverallTab tabKey="1" document={document} />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" title={<span><IconList style={{ marginRight: 6 }} />Metadata</span>}>
          <MetadataTab tabKey="2" document={document} />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" title={<span><IconHistory style={{ marginRight: 6 }} />Edit History</span>}>
          <EditHistoryTab document={document}/>
        </Tabs.TabPane>
        <Tabs.TabPane key="4" title={<span><IconFindReplace style={{ marginRight: 6 }} />Text Content</span>}>
          <TextContentTab document={document}/>
        </Tabs.TabPane>
        <Tabs.TabPane key="5" title={<span><IconImage style={{ marginRight: 6 }} />Images</span>}>
          <ImagesTab document={document}/>
        </Tabs.TabPane>
      </Tabs>}
    </Modal>
  );
}