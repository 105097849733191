import React, { useCallback, useState } from "react";
import { Button, Divider, Grid, Form, Input, Message } from "@arco-design/web-react";
import { IconPlus } from "@arco-design/web-react/icon";
import { createDocumentType } from "../../api/documents";


export function DocumentTypeForm(props) {
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);


  const onFormSubmit = useCallback((formData) => {
    setWorking(true);
    createDocumentType(formData.name, formData.sub_type).then((response) => {
      if (!response.ok) {
        setWorking(false);
        props.onFail(response);
        return;
      }
      return response.json();
    }).then((json) => {
      setWorking(false);
      props.onSuccess(json);
    }).catch((error) => {
      setWorking(false);
      props.onError(error);
    })
  }, [props.onSuccess, props.onError, props.onFail]);


  return (
    <Form form={form} onSubmit={(formData) => {onFormSubmit(formData)}} labelAlign="left">
      <Form.Item label='Name' field="name" rules={[{required: true}]}>
        <Input name="name" placeholder='Name of document type (e.g. bank statement)' />
      </Form.Item>
      <Form.Item label='Sub-Type' field="sub_type" rules={[{required: false}]}>
        <Input name="sub_type" placeholder='Sub-type of document (e.g. current account)' />
      </Form.Item>
      <Divider/>
      <Grid.Row align="end" style={{justifyContent: "flex-end"}}>
        <Grid.Col md={24} style={{justifyContent: "flex-end"}}>
          <Grid.Row style={{justifyContent: "flex-end"}}>
            <Button loading={working} type="primary" style={{margin: 10}} du htmlType='submit'>Submit</Button>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
}