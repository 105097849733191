import { Layout, Menu } from "@arco-design/web-react";
import { useNavigate } from 'react-router';
import { IconFile, IconHome, IconPublic, IconSend, IconSettings, IconUserGroup } from "@arco-design/web-react/icon";
import { SimpleAccountDisplay } from '../presentational/AccountDisplay';



export function DefaultPageLayout(props) {

  let navigate = useNavigate();
  async function handleDashboardClick() { navigate("/dashboard"); }
  async function handleDocumentsClick() { navigate("/documents"); }
  async function handleCharacterChecksClick() { navigate("/characterChecks"); }
  async function handleAccountClick() { console.log("CLICKED"); }
  async function handleNetworkClick() { navigate("/network"); }
  async function handleWebhooksClick() { navigate("/webhooks"); }

  return(
    <Layout style={{maxHeight: "100vh"}}>
      <Layout.Sider trigger={null} breakpoint='xl'>
        <SimpleAccountDisplay />
        <Menu align="left" style={{height: "100vh"}} hasCollapseButton mode="pop">
          <Menu.Item key="dashboard" onClick={handleDashboardClick}>
            <IconHome />
            Dashboard
          </Menu.Item>
          <Menu.Item key="people" onClick={handleCharacterChecksClick}>
            <IconUserGroup />
            Character Checks
          </Menu.Item>
          <Menu.Item key="documents" onClick={handleDocumentsClick}>
            <IconFile />
            Document Checks
          </Menu.Item>
          <Menu.Item key="network" onClick={handleNetworkClick}>
            <IconPublic />
            Network
          </Menu.Item>
          <Menu.Item key="account" onClick={handleAccountClick}>
            <IconSettings />
            Account
          </Menu.Item>
          <Menu.Item key="notifications" onClick={handleWebhooksClick}>
            <IconSend />
            Notifications
          </Menu.Item>
          {/* other data to be added, emails, addresses, companies etc etc */}
        </Menu>
      </Layout.Sider>
      <Layout style={{paddingLeft: 30, paddingRight: 30}}>
        <Layout.Header align="left" style={{minHeight: 151}}>
          <h2 style={{fontWeight: "600"}}>{props.pageHeader}</h2>
          <p style={{fontWeight: "300", marginTop: 30, marginBottom: 30}}>{props.pageDescription}</p>
        </Layout.Header>
        <Layout.Content align="left">
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}