import { Grid, Link, Table } from "@arco-design/web-react";
import React from "react";
import { ResultTag } from "./presentational";



export function EditHistoryTab(props) {
  let versionDisplay = [];
  for (let i = 0;i < props.document.version_extraction.versions.length; i++) {
    const currentVersion = props.document.version_extraction.versions[i];
    versionDisplay.push(
      <><Link href={currentVersion.url}>Version {currentVersion.version_number}</Link><br/></>
    );
  }
  let comparisonDisplay = [];
  for (let i = 0;i < props.document.version_check.comparisons.length; i++) {
    const currentComparison = props.document.version_check.comparisons[i];
    comparisonDisplay.push(
      <><Link href={currentComparison.url}>Comparison {currentComparison.version_a} to {currentComparison.version_b}</Link><br/></>
    );
  }
  return (
    <Grid.Row>
      <Grid.Col md={24}>
        <h3>Result: <ResultTag result={props.document.version_check.result}/></h3>
      </Grid.Col>
      <Grid.Col md={12}>
        <h3>Versions</h3>
        {versionDisplay}
      </Grid.Col>
      <Grid.Col md={12}>
        <h3>Comparisons</h3>
        {comparisonDisplay}
      </Grid.Col>
    </Grid.Row>
  );
}