import React, { useState, useCallback } from 'react';
import { Select, Switch, Grid, Button, Form, Input, Message, Checkbox, Upload, Divider } from '@arco-design/web-react';
import { COUNTRIES } from '../../countries';
import { createPDFDocument, getSignedURLForUpload } from '../../api/documents';



export function AddDocumentForm(props) {
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [runBackground, setRunBackground] = useState(false);

  const onFormSubmit = useCallback(async (formData) => {

    let uploadedFile = formData.uploaded_file
    if (uploadedFile === null || uploadedFile === undefined) {
      Message.warning("No file selected");
      setWorking(false);
      return;
    }

    if (uploadedFile.length === 0) {
      Message.warning("No file selected");
      setWorking(false);
      return;
    }
    
    let fileToUpload = uploadedFile[0];
    const fileName = fileToUpload.name;
    const fileType = fileName.toLowerCase().endsWith("pdf") ? "pdf" : "image";
    setWorking(true);

    let signedURLResponse = await getSignedURLForUpload(fileName, fileType);
    if (!signedURLResponse.ok) {
      Message.error("Could not upload document.");
      setWorking(false);
      return;
    }

    let signedURLJSON = await signedURLResponse.json();
    let signedURL = signedURLJSON.url;

    let uploadResponse = await fetch(
      signedURL,
      {
        body: fileToUpload.originFile,
        method: "PUT",
        headers: {
          "Content-Type": 'application/octet-stream',
        }
        
      }
    );

    if (!uploadResponse.ok) {
      Message.error("Could not upload document.");
      setWorking(false);
      return;
    }

    let createResponse = await createPDFDocument(
      formData.subject_first_name,
      formData.subject_last_name,
      formData.subject_email,
      fileName,
      signedURL.split("?")[0],
      runBackground,
      formData.document_type,
    );

    if (!createResponse.ok) {
      Message.error("Could not create document check.");
      setWorking(false);
      return;
    }

    let creationData = await createResponse.json();
    setWorking(false);
    props.onSuccess(creationData, runBackground);

  }, [props.onSuccess]);
  
  return (
    <Form form={form} onSubmit={(formData) => {onFormSubmit(formData)}}>
      <Grid.Row>
        <Grid.Col md={24}>
          <div className='file-upload'>
            <Form.Item label="Upload File" field="uploaded_file">
              <Upload
                multiple={false}
                action='/'
                autoUpload={false}
                onChange={(fileList, file) => {}}
              >
                <div className='trigger'>
                  <div>
                    Drag the file here or
                    <span style={{ color: '#3370FF', padding:'0 4px' }} >
                      Click to upload
                    </span>
                  </div>
                </div>
              </Upload>
            </Form.Item>
          </div>
          <Form.Item label="Document Type" field="document_type">
            <Select
              onChange={(e) => {form.setFieldValue("document_type", e)}}
              options={props.documentTypeOptions.map(option => {
                return {
                  label: `${option.name}: ${option.sub_type}`,
                  value: option.uuid
                }
              })}
            />
          </Form.Item>
          <Form.Item label='First Name' field="subject_first_name">
            <Input name="subject_first_name" placeholder='enter subject first name...' />
          </Form.Item>
          <Form.Item label='Last Name' field="subject_last_name">
            <Input name="subject_last_name" placeholder='enter subject last name...' />
          </Form.Item>
          <Form.Item label='Email' field="subject_email">
            <Input name="subject_email" placeholder='enter subject email...'/>
          </Form.Item>
          <Form.Item label="Run Background">
            <Grid.Row style={{justifyItems: "start", justifyContent: 'flex-start', alignItems: 'center'}}>
              <Switch style={{marginRight: 20}} onChange={(e) => {console.log("event", e); setRunBackground(e)}}/>
              <p style={{fontWeight: "bold", fontSize: 12}}>(Recommended)</p>
              </Grid.Row>
          </Form.Item>
          <Divider/>
          <Grid.Row align="end" justify='end'>
            <Form.Item>
              <Button type='primary' loading={working} htmlType='submit'>Submit</Button>
            </Form.Item>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
}