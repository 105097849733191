import { converDateStringToReadable } from "../../../utils/dates";

export function formatMetadata(value, isBool, isDatetime) {
  if (value === null || value === undefined) {
    return '-';
  }
  if (isBool) {
    return value ? "Yes" : "No";
  }

  if (isDatetime) {
    return converDateStringToReadable(value);
  }

  return value;
}

export function convertFieldToTitle(fieldName) {
  return fieldName
    .replace(/_/g, " ")
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}


export function constructDescriptionData(data) {
  let descriptionFields = [];
  if (data !== null) {
    for (const key in data) {
      descriptionFields.push({label: convertFieldToTitle(key), value: data[key]});
    }
  }
  return descriptionFields;
}
