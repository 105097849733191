import React from 'react';
import { useNavigate } from "react-router-dom";

import { Layout, Grid, } from '@arco-design/web-react';
import art from '../../images/art_1.png';



export function HomePage() {
  const navigate = useNavigate();

  return (
    <Layout>
      <Layout.Content style={{paddingLeft: 50, paddingRight: 50}}>
        <div align="right" style={{marginTop: 10}}>
          <div className="outline-cta" align="middle" onClick={() => {navigate("/login");}}>Login</div>
        </div>
        <Grid.Row style={{marginTop: "5%"}}>
          <Grid.Col md={10} style={{paddingTop: 60}}>
            <p className="landing-header-intro">Validate PDF documents with confidence</p>
            <p className="landing-header">S<span style={{fontSize: "5.5vw"}}>CRY</span>T<span style={{fontSize: "5.5vw"}}>ECH</span></p>
            <p className='description'>Validate the authenticity of PDF documents in seconds with comprehensive,
              affordable checks using our easy to use interface or via our API.</p>
            <div className='action-row'>
              <div className="action-link" align="middle">Features</div>
              <div className="action-link" align="middle">Sign Up</div>
            </div>
          </Grid.Col>
          <Grid.Col md={14} align="right">
            <img src={art} alt="fraud-artwork" width={"70%"}/>
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    </Layout>
  );
}
