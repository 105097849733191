import { Grid, Link, Message, Spin} from '@arco-design/web-react';
import { IconHome, IconPlus } from '@arco-design/web-react/icon';
import React from 'react';
import { deleteInstitution, listInstitutions } from '../../../api/documents';
import { InstitutionModal } from './AddInstitutionsModal';
import { InstitutionsTable } from './institutionsTable';


export class InstitutionsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showAddModal: false,
      showEditmodal: false,
      selectedRecord: null,
      results: []
    };

    this.handeEditInstitution = this.handeEditInstitution.bind(this);
    this.handleAddInstition = this.handleAddInstition.bind(this);
    this.deleteInstitution = this.deleteInstitution.bind(this);
  }

  handleAddInstition(data) {
    let currentResults = structuredClone(this.state.results);
    currentResults.push(data);
    this.setState({showAddModal: false, results: currentResults});
  }

  handeEditInstitution(data) {
    let newResults = [];
    for (let i = 0; i < this.state.results.length; i++) {
      const currentResult = this.state.results[i];
      if (currentResult.uuid === data.uuid) {
        newResults.push(data);
      } else {
        newResults.push(currentResult);
      }
    }
    this.setState({showEditModal: false, results: newResults});
  }

  deleteInstitution(uuid) {
    deleteInstitution(uuid).then((response) => {
      if (!response.ok) {
        Message.error("Could not delete institution");
        return;
      } else {
        let currentInstitutions = structuredClone(this.state.results);
        let newInstitutions = [];
        for (let i = 0; i < currentInstitutions.length; i++) {
          let institution = currentInstitutions[i];
          if (institution.uuid !== uuid) {
            newInstitutions.push(institution);
          }
        }
        Message.success("Institution deleted successfully.");
        this.setState({results: newInstitutions});
      }
    }).catch((error) => {
      console.log(error);
      Message.error("Could not delete institution.");
    });
  }

  componentDidMount() {
    listInstitutions({page: 1}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load institutions");
        this.setState({loading: false});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({results: json, loading: false});
    }).catch((error) => {
      console.log(error);
      Message.error("Could not load institutions.");
      this.setState({loading: false});
    });
  }

  render() {
    if (this.state.loading) {
      return <Spin/>
    }
    let typeOptions = this.state.results.flatMap((i) => i.institution_type === null ? [] : i.institution_type);
    return (
      <>
        <InstitutionModal
          visible={this.state.showEditModal}
          onCancel={() => {this.setState({showEditModal: false})}}
          onFormSuccess={this.handeEditInstitution}
          onFormError={() => {Message.error("Could not update institution"); this.setState({showEditModal: false})}}
          onFormFail={() => {Message.error("Could not update institution"); this.setState({showEditModal: false})}}
          typeOptions={typeOptions}
          instance={this.state.selectedRecord}
        />
        <InstitutionModal
          visible={this.state.showAddModal}
          onCancel={() => {this.setState({showAddModal: false})}}
          onFormSuccess={this.handleAddInstition}
          onFormError={() => {Message.error("Could not add institution"); this.setState({showAddModal: false})}}
          onFormFail={() => {Message.error("Could not add institution"); this.setState({showAddModal: false})}}
          typeOptions={typeOptions}
        />
        <Grid.Row style={{height: 52}}>
          <Grid.Col md={24} style={{justifyContent: "flex-end"}}>
            <Grid.Row style={{justifyContent: "flex-end"}}>
              <Link onClick={() => {this.setState({showAddModal: true})}} icon={<IconPlus/>} style={{marginRight: 15}}>Add</Link>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <InstitutionsTable
          data={this.state.results}
          loading={this.state.loading}
          onEditClick={(record) => {this.setState({selectedRecord: record, showEditModal: true})}}
          onDelete={(uuid) => {this.deleteInstitution(uuid)}}
        />
      </>
    );
  }
}