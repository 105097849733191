import { Alert, Progress } from "@arco-design/web-react";
import useWebSocket from 'react-use-websocket';

import { PDF_PIPELINE_WEBSOCKET_URL } from "../../api/conf";
import React, { useState, useCallback } from "react";
import { IconCheckCircleFill, IconCloseCircleFill, IconExclamationCircleFill, IconLoading, IconQuestionCircleFill, IconStop } from "@arco-design/web-react/icon";


export function DocumentEventStream(props) {

  const [receivedEvents, setReceivedEvents] = useState([]);
  const handleSendMessage = useCallback(() => sendMessage('received'), []);

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(`${PDF_PIPELINE_WEBSOCKET_URL}/${props.uuid}/ws`, {
    onOpen: () => console.log('opened'),
    onClose: () => console.log("closed"),
    onMessage: (event) => {
      handleSendMessage();
      const json = JSON.parse(event.data);
      if (json.pipeline_status === "STARTING") {
        setReceivedEvents(json.step_values);
        props.onStart();
      } else if (json.pipeline_status === "COMPLETE") {
        props.onCompletion(json);
      } else {
        props.onEvent(json);
        let currentEvents = receivedEvents;
        let newEvents = [];
        for (let i = 0; i < currentEvents.length; i++) {
          if (currentEvents[i].key == json.key) {
            newEvents.push(json);
          } else {
            newEvents.push(currentEvents[i]);
          }
        }
        setReceivedEvents(newEvents);
      }
    },
  });

  let alerts = [];
  for (let i = 0; i < receivedEvents.length; i++) {
    let currentEvent = receivedEvents[i];
    let result;
    let alertType;
    let icon;
    switch(currentEvent.value) {
      case "COMPLETE":
        result = "Completed";
        alertType = "success";
        icon = <IconCheckCircleFill/>;
        break;
      case "PASSED":
        result = "Passed";
        alertType = "success";
        icon = <IconCheckCircleFill/>;
        break;
      case "FAILED":
        result = "Failed";
        alertType = "warning";
        icon = <IconExclamationCircleFill/>
        break;
      case "ERROR":
        result = "Error";
        alertType = "error";
        icon = <IconCloseCircleFill/>
        break;
      case "UNCERTAIN":
        result = "Inconclusive";
        alertType = "info";
        icon = <IconQuestionCircleFill/>
        break;
      default:
        result = `...`;
        alertType = "info";
        icon = <IconLoading/>
        break;
    }

    alerts.push(
      <Alert
        style={{ marginBottom: 10 }}
        icon={icon}
        type={alertType}
        content={currentEvent.name}
        action={<span style={{fontWeight: "bold"}}>{result}</span>}
      />
    )
  }

  return <div>{alerts}</div>;
}