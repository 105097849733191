import { Button, Table } from "@arco-design/web-react";
import { IconEdit } from "@arco-design/web-react/icon";
import { converDateStringToReadable } from "../../../utils/dates";
import { DeletePopover } from "../../presentational/tableDisplay";


export function InstitutionsTable(props) {

  return (
    <Table
      size="small"
      hover={true}
      loading={props.loading}
      pagination={false}
      data={props.data}
      columns={[
        {title: "Name", dataIndex: "name"},
        {title: "Type", dataIndex: "institution_type"},
        {title: "Created At", dataIndex: "created_at", render: (_, record) => {
          return converDateStringToReadable(record.created_at);
        }},
        {title: "", dataIndex: ".", align: "right", render: (_, record) => {
          return (
            <div>
              <Button
                style={{marginLeft: 5}}
                iconOnly={true}
                icon={<IconEdit/>}
                onClick={() => {props.onEditClick(record)}}
              />
              <DeletePopover
                title="Confirm"
                content="Are you sure you want to delete?"
                onOk={() => {props.onDelete(record.uuid)}}
                onCancel={() => {}}
                buttonStyle={{marginLeft: 5}}
              />
            </div>
          );
        }}
      ]}
    />
  );
}