import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { Navigate } from 'react-router-dom';

import { Steps, Card, DatePicker, Spin, Divider, Message, Layout, Button, Input, Grid, Table, Tabs, Form, Checkbox, Descriptions } from "@arco-design/web-react";
import { IconPlus, IconLeft, IconRefresh, IconExport, IconFile, IconSettings, IconRight } from '@arco-design/web-react/icon';
import { DefaultPageLayout } from './layouts';
import { BasicInformationForm, AddressForm, EmploymentHistoryForm, QuickAddTableSummary, AddAddressTableSummary, AddEmploymentTableSummary, AddOtherDocumentTableSummary } from '../forms/characterCheckForms';
import { DocumentList } from '../presentational/tableDisplay';




export default class CharacterCheckAddScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 5,
      firstName: null,
      lastName: null,
      email: null,
      dateOfBirth: null,
      physicalAddresses: [],
      employmentHistory: [],
      ipAddresses: [],
      domains: [],
      alternativeEmails: [],
      otherDocuments: [],
      newIPInputValue: null,
      newDomainInputValue: null,
      newEmailInputValue: null,
    }

    // utils
    this.incrementStep = this.incrementStep.bind(this);
    this.decrementStep = this.decrementStep.bind(this);

    // submission methods
    this.handleAddDocumentSubmit = this.handleAddDocumentSubmit.bind(this);
    this.handleQuickDelete = this.handleQuickDelete.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleDomainSubmit = this.handleDomainSubmit.bind(this);
    this.handleIPSubmit = this.handleIPSubmit.bind(this);
    this.handleAddressSubmit = this.handleAddressSubmit.bind(this);
    this.handleBasicInformationSubmit = this.handleBasicInformationSubmit.bind(this);
    this.handleAddressDelete = this.handleAddressDelete.bind(this);
    this.handleEmploymentSubmit = this.handleEmploymentSubmit.bind(this);
    this.handleEmploymentDelete = this.handleEmploymentDelete.bind(this);
    this.handleIPAddressDelete = this.handleIPAddressDelete.bind(this);
    this.handleEmailDelete = this.handleEmailDelete.bind(this);

    // render methods
    this.renderBasicInformationStepContent = this.renderBasicInformationStepContent.bind(this)
    this.renderAddressHistoryStepContent = this.renderAddressHistoryStepContent.bind(this)
    this.renderEmploymentHistoryStepContent = this.renderEmploymentHistoryStepContent.bind(this)
    this.renderInternetStepContent = this.renderInternetStepContent.bind(this);
    this.renderDocumentsStepContent = this.renderDocumentsStepContent.bind(this)
    this.renderConfirmationStepContent = this.renderConfirmationStepContent.bind(this)
    this.renderCurrentStep = this.renderCurrentStep.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  incrementStep() {
    this.setState({currentStep: this.state.currentStep + 1});
  }
  decrementStep() {
    this.setState({currentStep: this.state.currentStep - 1});
  }

  handleAddDocumentSubmit(formData) {
    let documents = [...this.state.otherDocuments];
    documents.push({
      key: documents.length + 1,
      document_type: formData.document_type,
      document: formData.document,
    });

    this.setState({otherDocuments: documents});
  }

  handleBasicInformationSubmit(formData) {

    this.setState({
      firstName: formData.first_name,
      lastName: formData.last_name,
      dateOfBirth: `${formData.date_of_birth} 00:00:00`,
      email: formData.email,
      currentStep: 3,
    });
  }

  handleEmailSubmit(email) {
    let emails = [...this.state.alternativeEmails];
    emails.push({
      key: emails.length + 1,
      email: email,
    });

    this.setState({alternativeEmails: emails, newEmailInputValue: null});
  }

  handleDomainSubmit(domain) {
    let domains = [...this.state.domains];
    domains.push({
      key: domains.length + 1,
      domain: domain,
    });

    this.setState({domains: domains, newDomainInputValue: null});
  }

  handleIPSubmit(newIP) {
    let ipAddresses = [...this.state.ipAddresses];
    ipAddresses.push({
      key: ipAddresses.length + 1,
      ip_address: newIP,
    });

    this.setState({ipAddresses: ipAddresses, newIPInputValue: null});
  }

  handleAddressSubmit(formData) {
    let addresses = [...this.state.physicalAddresses];
    addresses.push({
      key: addresses.length + 1,
      formatted_address: formData.address.label,
      address_id: formData.address.value.place_id,
      start_date: formData.start_date,
      end_date: formData.end_date,
      supporting_documents: formData.supporting_documents
    });

    this.setState({physicalAddresses: addresses});
  }

  handleAddressDelete(key) {
    let addresses = [];
    for (let i = 0; i < this.state.physicalAddresses.length; i++) {
      if (this.state.physicalAddresses[i].key !== key) {
        addresses.push(this.state.physicalAddresses[i]);
      }
    }
    this.setState({physicalAddresses: addresses});
  }

  handleEmploymentSubmit(formData) {
    let employment = [...this.state.employmentHistory];
    employment.push({
      key: employment.length + 1,
      company_name: formData.company_name,
      start_date: formData.start_date,
      end_date: formData.end_date,
      supporting_documents: formData.supporting_documents
    });

    this.setState({employmentHistory: employment});
  }

  handleEmploymentDelete(key) {
    let employment = [];
    for (let i = 0; i < this.state.employmentHistory.length; i++) {
      if (this.state.employmentHistory[i].key !== key) {
        employment.push(this.state.employmentHistory[i]);
      }
    }
    this.setState({employmentHistory: employment});
  }

  handleIPAddressDelete(key) {
    let ipAddresses = [];
    for (let i = 0; i < this.state.ipAddresses.length; i++) {
      if (this.state.ipAddresses[i].key !== key) {
        ipAddresses.push(this.state.ipAddresses[i]);
      }
    }
    this.setState({ipAddresses: ipAddresses});
  }

  handleEmailDelete(key) {
    let emails = [];
    for (let i = 0; i < this.state.alternativeEmails.length; i++) {
      if (this.state.alternativeEmails[i].key !== key) {
        emails.push(this.state.alternativeEmails[i]);
      }
    }
    this.setState({alternativeEmails: emails});
  }

  handleQuickDelete(key, arrayName) {
    let objects = [];
    for (let i = 0; i < this.state[arrayName].length; i++) {
      let currentObj = this.state[arrayName][i];
      if (currentObj.key !== key) {
        objects.push(currentObj);
      }
    }
    let newState = {};
    newState[arrayName] = objects;
    this.setState(newState);
  }

  renderBasicInformationStepContent() {
    return (
      <Grid.Row align="start">
        <Grid.Col>
          <h2 align="left">Basic Information</h2>
          <BasicInformationForm handleSubmit={this.handleBasicInformationSubmit}/>
        </Grid.Col>
      </Grid.Row>
    )
  }

  renderAddressHistoryStepContent() {
    return (
      <Grid.Row align="start">
        <Grid.Col md={24}>
          <Divider/>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={this.state.physicalAddresses}
            columns={[
              {title: "Address",  width: "30%", dataIndex: "formatted_address"},
              {title: "Start Date", width: "20%", dataIndex: "start_date", render: (_, record) => {return record.start_date !== null ? record.start_date : "-"}},
              {title: "End Date", width: "20%", dataIndex: "end_date", render: (_, record) => {return record.end_date !== null ? record.end_date : "-"}},
              {title: "Documents", dataIndex: "", render: (_, record) => {return <DocumentList documents={record.supporting_documents}/>}},
              {title: "", width: "10%", dataIndex: "", align: "right", render: (_, record) => <Button style={{margin: 5}} onClick={() => {this.handleAddressDelete(record.key)}} type="secondary" status="warning" size="small">Delete</Button>},
            ]}
            summary={(currentData) => {return ( <AddAddressTableSummary buttonText="Add" handleSubmit={(formData) => { this.handleAddressSubmit(formData); }}/> )}}
          />
        </Grid.Col>
        <Grid.Col md={24} align="right">
          <Button type="primary" style={{margin: 10}} onClick={() => {this.incrementStep()}}>Continue <IconRight/></Button>
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderEmploymentHistoryStepContent() {
    return (
      <Grid.Row align="start">
        <Grid.Col md={24}>
          <Divider/>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={this.state.employmentHistory}
            columns={[
              {title: "Company", width: "25%", dataIndex: "company_name"},
              {title: "Start Date", width: "20%", dataIndex: "start_date", render: (_, record) => {return record.start_date !== null && record.start_date  !== undefined ? record.start_date : "-"}},
              {title: "End Date", width: "20%", dataIndex: "end_date", render: (_, record) => {return record.end_date !== null && record.end_date !== undefined ? record.end_date : "-"}},
              {title: "Documents", dataIndex: "", render: (_, record) => {return <DocumentList documents={record.supporting_documents}/>}},
              {title: "", width: "10%",  dataIndex: "", align: "right", render: (_, record) => <Button style={{margin: 5}} onClick={() => {this.handleEmploymentDelete(record.key)}} type="secondary" status="warning" size="small">Delete</Button>},
            ]}
            summary={(currentData) => {
              return ( <AddEmploymentTableSummary buttonText="Add" handleSubmit={(formData) => { this.handleEmploymentSubmit(formData); }}/> );
            }}
          />
        </Grid.Col>
        <Grid.Col md={24} align="right">
          <Button type="primary" style={{margin: 10}} onClick={() => {this.incrementStep()}}>Continue <IconRight/></Button>
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderInternetStepContent() {
    return (
      <Grid.Row>
        <Grid.Col md={8}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={this.state.ipAddresses}
            columns={[
              {title: "IP Addresses", dataIndex: "ip_address"},
              {
                title: "",
                dataIndex: "",
                align: "right",
                render: (_, record) => {
                  return (
                    <Button
                      style={{margin: 5, width: "100%"}}
                      onClick={() => {this.handleQuickDelete(record.key, "ipAddresses")}}
                      type="secondary"
                      status="warning"
                      size="mini"
                    >
                      Delete
                    </Button>
                  )
                }
              },
            ]}
            rowKey={(record) => record.key}
            summary={(currentData) => {
              return (
                <QuickAddTableSummary
                  inputName="ip_address"
                  inputValue={this.state.newIPInputValue}
                  placeholder="Enter IP address..."
                  onInputChange={(value) => {this.setState({newIPInputValue: value})}}
                  buttonText="Add"
                  handleSubmit={() => {this.handleIPSubmit(this.state.newIPInputValue)}}
                />
              );
            }}
          />
        </Grid.Col>
        <Grid.Col md={8}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={this.state.domains}
            columns={[
              {title: "Domains", dataIndex: "domain"},
              {
                title: "",
                dataIndex: "",
                align: "right",
                render: (_, record) => {
                  return (
                    <Button
                      style={{margin: 5}}
                      onClick={() => {this.handleQuickDelete(record.key, "domains")}}
                      type="secondary"
                      status="warning"
                      size="small"
                    >
                      Delete
                    </Button>
                  )
                }
              },
            ]}
            rowKey={(record) => record.key}
            summary={(currentData) => {
              return (
                <QuickAddTableSummary
                  inputName="domain"
                  inputValue={this.state.newDomainInputValue}
                  placeholder="Enter domain..."
                  onInputChange={(value) => {this.setState({newDomainInputValue: value})}}
                  buttonText="Add"
                  handleSubmit={() => {this.handleDomainSubmit(this.state.newDomainInputValue)}}
                />
              );
            }}
          />
        </Grid.Col>
        <Grid.Col md={8}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={this.state.alternativeEmails}
            columns={[
              {title: "Alternative Emails", dataIndex: "email"},
              {
                title: "",
                dataIndex: "",
                align: "right",
                render: (_, record) => {
                  return (
                    <Button
                      style={{margin: 5}}
                      onClick={() => {this.handleQuickDelete(record.key, "alternativeEmails")}}
                      type="secondary"
                      status="warning"
                      size="small"
                    >
                      Delete
                    </Button>
                  )
                }
              },
            ]}
            summary={(currentData) => {
              return (
                <QuickAddTableSummary
                  inputName="email"
                  inputValue={this.state.newEmailInputValue}
                  placeholder="Enter email..."
                  onInputChange={(value) => {this.setState({newEmailInputValue: value})}}
                  buttonText="Add"
                  handleSubmit={() => {this.handleEmailSubmit(this.state.newEmailInputValue)}}
                />
              );
            }}
          />
        </Grid.Col>
        <Grid.Col md={24} align="right">
          <Button type="primary" style={{margin: 10}} onClick={() => {this.incrementStep()}}>Continue <IconRight/></Button>
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderDocumentsStepContent() {
    return (
      <Grid.Row>
        <Grid.Col md={24}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={this.state.otherDocuments}
            columns={[
              {title: "Document Type", dataIndex: "document_type"},
              {title: "File", dataIndex: "document", render: (_, record) => {return <DocumentList documents={[record.document]}/>}},
              {
                title: "",
                dataIndex: "",
                align: "right",
                render: (_, record) => {
                  return (
                    <Button
                      style={{margin: 5, width: "100%"}}
                      onClick={() => {this.handleQuickDelete(record.key, "otherDocuments")}}
                      type="secondary"
                      status="warning"
                      size="mini"
                    >
                      Delete
                    </Button>
                  )
                }
              },
            ]}
            rowKey={(record) => record.key}
            summary={(currentData) => {
              return (
                <AddOtherDocumentTableSummary buttonText="Add" handleSubmit={(formData) => {this.handleAddDocumentSubmit(formData)}}/>
              );
            }}
          />
        </Grid.Col>
        <Grid.Col md={24} align="right">
          <Button type="primary" style={{margin: 10}} onClick={() => {this.incrementStep()}}>Continue <IconRight/></Button>
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderConfirmationStepContent() {
    return (
      <>
        <Descriptions
          title={<h2>Basic Information</h2>}
          border
          colon=': '
          layout='horizontal'
          column={1}
          data={[
            {label: "First Name", value: this.state.firstName},
            {label: "Last Name", value: this.state.lastName},
            {label: "Email", value: this.state.email},
            {label: "Date of Birth", value: this.state.dateOfBirth},
          ]}
        />
        <h2>Address History</h2>
        <Table
          size="mini"
          hover={true}
          pagination={false}
          data={this.state.physicalAddresses}
          columns={[
            {title: "Address",  width: "30%", dataIndex: "formatted_address"},
            {title: "Start Date", width: "20%", dataIndex: "start_date", render: (_, record) => {return record.start_date !== null ? record.start_date : "-"}},
            {title: "End Date", width: "20%", dataIndex: "end_date", render: (_, record) => {return record.end_date !== null ? record.end_date : "-"}},
            {title: "Documents", dataIndex: "", render: (_, record) => {return <DocumentList documents={record.supporting_documents}/>}},
          ]}
        />
        <h2>Employment History</h2>
        <Table
          size="mini"
          hover={true}
          pagination={false}
          data={this.state.employmentHistory}
          columns={[
            {title: "Company", width: "25%", dataIndex: "company_name"},
            {title: "Start Date", width: "20%", dataIndex: "start_date", render: (_, record) => {return record.start_date !== null && record.start_date  !== undefined ? record.start_date : "-"}},
            {title: "End Date", width: "20%", dataIndex: "end_date", render: (_, record) => {return record.end_date !== null && record.end_date !== undefined ? record.end_date : "-"}},
            {title: "Documents", dataIndex: "", render: (_, record) => {return <DocumentList documents={record.supporting_documents}/>}},
          ]}
        />
        <h2>Internet Activity</h2>
        <Grid.Row>
          <Grid.Col md={8} style={{paddingRight: 10}}>
            <Table
              size="mini"
              hover={true}
              pagination={false}
              data={this.state.ipAddresses}
              columns={[{title: "IP Addresses", dataIndex: "ip_address"}]}
              rowKey={(record) => record.key}
            />
          </Grid.Col>
          <Grid.Col md={8} style={{paddingLeft: 10, paddingRight: 10}}>
            <Table
              size="mini"
              hover={true}
              pagination={false}
              data={this.state.domains}
              columns={[{title: "Domains", dataIndex: "domain"}]}
              rowKey={(record) => record.key}
            />
          </Grid.Col>
          <Grid.Col md={8} style={{paddingLeft: 10}}>
            <Table
              size="mini"
              hover={true}
              pagination={false}
              data={this.state.alternativeEmails}
              columns={[{title: "Emails", dataIndex: "email"}]}
              rowKey={(record) => record.key}
            />
          </Grid.Col>
        </Grid.Row>
      </>
    );
  }

  renderCurrentStep() {
    switch(this.state.currentStep) {
      case 1:
        return this.renderBasicInformationStepContent();
      case 2:
        return this.renderAddressHistoryStepContent();
      case 3:
        return this.renderEmploymentHistoryStepContent();
      case 4:
        return this.renderInternetStepContent();
      case 5:
        return this.renderDocumentsStepContent();
      case 6:
        return this.renderConfirmationStepContent();
      default:
        break;
    }
  }

  renderContent () {
    return (
      <>
      <Grid.Row>
        <Grid.Col md={24} style={{marginBottom: 40}}>
          <Steps size='small' current={this.state.currentStep} direction="horizontal">
            <Steps.Step title="Basic Information"/>
            <Steps.Step title="Address History"/>
            <Steps.Step title="Employment"/>
            <Steps.Step title="Internet Activity"/>
            <Steps.Step title="Other Documents"/>
            <Steps.Step title="Confirm"/>
          </Steps>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={24}>
          {this.renderCurrentStep()}
        </Grid.Col>
      </Grid.Row>
      </>
    );
  }

  render() {
    let pageContent = this.renderContent();
    return (
      <DefaultPageLayout pageHeader="New Character Check">
        {pageContent}
      </DefaultPageLayout>
    );
  }
}