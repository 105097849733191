import { Message } from '@arco-design/web-react';
import {create} from 'zustand';
import { createPDFDocument, listPDFDocuments, retrievePDFDocument } from '../api/documents';
import { redirect } from 'react-router-dom';
import { handleTokenExpired } from '../api/base';

export const usePDFDocumentStore = create((set, get) => ({
  documentDetails: {},
  documents: [],
  retrieve: async (uuid) => {

    // return if in store
    let documentDetails = get().documentDetails;
    if (uuid in documentDetails) {
      return documentDetails[uuid];
    }

    // retrieve from API
    const response = await retrievePDFDocument(uuid);
    if (response.status === 403) {
      handleTokenExpired();
    }
    if (!response.ok) {
      Message.error("Could not load document");
      return;
    }

    // update store
    let currentDetails = structuredClone(documentDetails);
    currentDetails[uuid] = await response.json()
    set({ documentDetails: await currentDetails });
  },
  list: async (pageNumber) => {
    // retrieve list from API (TODO: pagination)
    const response = await listPDFDocuments({page: pageNumber});
    if (response.status === 403) {
      handleTokenExpired();
    }

    if (!response.ok) {
      Message.error("Could not load documents");
      return;
    }

    // set store
    set({ documents: await response.json() });
  },
  create: async (firstName, lastName, email, fileName, url, runBackground) => {
    const response = await createPDFDocument(
      firstName,
      lastName,
      email,
      fileName,
      url,
      runBackground,
    );
    if (!response.ok) {
      Message.error("Could not create document");
      return;
    }
    let data = await response.json();
    let details = structuredClone(documentDetails);
    details[data.uuid] = data;
    set({documentDetails: details});
  }
}));