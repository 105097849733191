import { Spin, Card, Layout, Grid, Descriptions, Tabs } from '@arco-design/web-react';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import { usePDFDocumentStore } from "../../../state/documents";
import { DefaultPageLayout } from '../layouts';
import { SummaryTab } from './SummaryTab';



export default function DocumentDetailScreen(props) {
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState(null);
  const params = useParams();
  const documentStore = usePDFDocumentStore();

  // load document
  documentStore.retrieve(params.uuid).then((document) => {
    if (document !== null && document !== undefined) {
      setLoading(false);
      setDocument(document);
    }
  });

  let content;

  if (loading) {
    content = <Spin/>
  } else {
    content = (
      <Layout.Content>
        <Grid.Row>
          <Grid.Col md={8}>
            <p>DOCUMENT HERE</p>
          </Grid.Col>
          <Grid.Col md={16}>
            <Tabs defaultActiveTab='1'>
              <Tabs.TabPane key="1" title="Summary">
                <SummaryTab document={document}/>
              </Tabs.TabPane>
              <Tabs.TabPane key="2" title="Metadata"></Tabs.TabPane>
              <Tabs.TabPane key="3" title="Edit History"></Tabs.TabPane>
              <Tabs.TabPane key="4" title="Text Content"></Tabs.TabPane>
              <Tabs.TabPane key="5" title="Images"></Tabs.TabPane>
            </Tabs>
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    );
  }

  return (
    <DefaultPageLayout pageHeader="Document Details" pageDescription="The document details page shows all the data that was extracted and checked for a given document. Here you will be able to override our auto-decision if needed after inspecting all of the provided data.">
      {content}
    </DefaultPageLayout>
  );
}