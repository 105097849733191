import { Modal, Link, Descriptions, Tabs, Table} from "@arco-design/web-react";
import { IconInfoCircle, IconList, IconLocation, IconFile, IconWifi, IconHome, IconCheckCircle, IconBranch, IconCodeBlock } from '@arco-design/web-react/icon';
import { converDateStringToReadable } from "../../utils/dates";
import { AssociationDataDisplay, AssociationsDisplay, dateRangeDisplay } from "./tableDisplay";
import { NetworkDecisionTag } from "./tags";


export function CharacterModal(props) {
  console.log(props.associations);
  return (
    <Modal
      visible={props.visible}
      title={<div align="left">{props.character.label}</div>}
      onCancel={props.onCancel}
      onOk={props.onCancel}
      style={{width: "90%"}}
    >
      <Tabs defaultActiveTab='1'>
        <Tabs.TabPane key="1" title={<span><IconInfoCircle style={{ marginRight: 6 }} />Basic Info</span>}>
          <Descriptions
            colon=': '
            layout='horizontal'
            column={1}
            title='Character Info'
            data={[
              {
                label: 'Name',
                value: props.character.label,
              },
              {
                label: "Date of Birth",
                value: props.character.date_of_birth === null ? "-" : converDateStringToReadable(props.character.date_of_birth),
              },
              {
                label: "Email",
                value: props.character.email,
              },
              {
                label: "Decision",
                value: <NetworkDecisionTag decision={props.character.decision}/>
              },
              {
                label: "Suspicion",
                value: props.character.suspicion,
              }
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" title={<span><IconLocation style={{ marginRight: 6 }} />Addresses</span>}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={props.character.physical_addresses}
            columns={[
              {title: "Current Address", dataIndex: "current_address", render: (_, record) => {record.current_address ? <IconCheckCircle/> : ""}},
              {title: "Address Line 1", dataIndex: "address_line_1"},
              {title: "Address Line 2", dataIndex: "address_line_2"},
              {title: "City", dataIndex: "city"},
              {title: "County/State", dataIndex: "county_or_state"},
              {title: "Post/Zipcode", dataIndex: "postal_or_zip_code"},
              {title: "Country", dataIndex: "country"},
              {title: "Dates", dataIndex: ".", render: (_, record) => {return dateRangeDisplay(record.start_date, record.end_date)}},
              {title: "Suspicion", dataIndex: "suspicion"},
              {title: "", dataIndex: ".", render: (_, record) => {return <Link target="_blank" href={`https://maps.google.com/?q=${record.lat},${record.lng}`}>View on map</Link>}}
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" title={<span><IconCodeBlock style={{ marginRight: 6 }} />IP Addresses</span>}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={props.character.ip_addresses}
            columns={[
              {title: "IP", dataIndex: "ip_address"},
              {title: "Suspicion", dataIndex: "suspicion"},
              {title: "Lat/lng", dataIndex: ".", render: (_, record) => `(${record.lat}, ${record.lng})`},
              {title: "", dataIndex: ".", render: (_, record) => {return <Link target="_blank" href={`https://maps.google.com/?q=${record.lat},${record.lng}`}>View on map</Link>}}
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="5" title={<span><IconWifi style={{ marginRight: 6 }} />Domains</span>}>
        </Tabs.TabPane>
        <Tabs.TabPane key="6" title={<span><IconHome style={{ marginRight: 6 }} />Employment History</span>}>
        </Tabs.TabPane>
        <Tabs.TabPane key="7" title={<span><IconFile style={{ marginRight: 6 }} />Documents</span>}>
        </Tabs.TabPane>
        <Tabs.TabPane key="8" title={<span><IconBranch style={{ marginRight: 6 }} />Associations</span>}>
          <Table
            size="mini"
            hover={true}
            pagination={false}
            data={props.associations}
            columns={[
              {title: "Name", dataIndex: ".", render: (_, record) => {return `${record.character.first_name} ${record.character.last_name}`}},
              {title: "Associations", dataIndex: ".", render: (_, record) => {return <AssociationsDisplay associations={record.associations}/>}},
              {title: "Associated Data", dataIndex: ".", render: (_, record) => { return  <AssociationDataDisplay data={record.data} /> }},
            ]}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="9" title={<span><IconList style={{ marginRight: 6 }} />Meta</span>}>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}