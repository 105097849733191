import React, {useState} from "react"

import { Button, Message, Modal, Progress, Spin, Tabs } from "@arco-design/web-react";
import { AddDocumentForm } from "../forms/documentForms";
import { IconUpload } from "@arco-design/web-react/icon";
import { DocumentEventStream } from "../presentational/DocumentProgress";
import { listDocumentTypes } from "../../api/documents";


export class AddDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      showPipelineProgress: false,
      showCompletion: false,
      createdData: null,
      progress: 0,
      documentTypes: [],
      loading: true,
    };

    this.calculatePercent = this.calculatePercent.bind(this);
    this.documentSubmitSuccess = this.documentSubmitSuccess.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    listDocumentTypes({page: 1}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load document types");
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({documentTypes: json, loading: false});
    }).catch((error) => {
      Message.error("Could not load document types");
      return;
    });
  }

  documentSubmitSuccess(createdDocument, runBackground) {
    this.setState({
      createdData: createdDocument,
      showForm: false,
      showPipelineProgress: !runBackground,
    });
  }

  calculatePercent() {
    return Math.round(this.state.progress / 9.0 * 100.0);
  }

  renderContent() {
    if (this.state.loading) {
      return <Spin/>
    }
    if (this.state.showForm) {
      return (<AddDocumentForm onSuccess={this.documentSubmitSuccess} documentTypeOptions={this.state.documentTypes}/>);
    }
    if (this.state.showPipelineProgress) {
      return (
        <>
        <DocumentEventStream
          uuid={this.state.createdData.uuid}
          onStart={() => {}}
          onCompletion={this.props.onSuccess}
          onEvent={(event) => {this.setState({progress: this.state.progress + 1})}}
        />
        <div align="right">
          {this.state.showCompletion ? <Button>View Results</Button> : null}
        </div>
        </>
      );
    }

  }

  render() {
    let percentage = this.calculatePercent()
    return (
      <Modal
        style={{width: "50%"}}
        visible={this.props.visible}
        closable={false}
        footer={null}
        title={
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <h2>Add Document</h2>
            {this.state.showPipelineProgress ? <div align="left" style={{paddingTop: 20, paddingBottom: 20, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
              <p style={{margin: 0, fontSize: 14}}>Progress</p>
              <Progress
                percent={percentage}
                color={{
                  '0%': 'rgb(var(--primary-6))',
                  '100%': 'rgb(var(--success-6))',
                }}
                animation
                width={150}
              />
            </div> : <></>}
          </div>
        }
        onCancel={this.props.onCancel}
        okText="Submit"
      >
        {this.renderContent()}
      </Modal>
    );
  }
}