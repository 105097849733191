import { Grid, Descriptions, Divider } from "@arco-design/web-react";
import { ResultTag } from "./presentational";
import React from "react";
import { constructDescriptionData, convertFieldToTitle, formatMetadata } from "./utils";


export function MetadataTab(props) {
  const metadata = props.document.metadata_extraction.pdf_metadata;
  const checks = props.document.metadata_check
  let checkValues = [];
  for (const key in checks) {
    checkValues.push({
      label: convertFieldToTitle(key), value: <ResultTag result={checks[key]} />
    });
  }
  return (
    <>
      <Descriptions
        layout="inline-vertical"
        title="Checks"
        style={{marginTop: 20, marginBottom: 20}}
        colon=": "
        data={checkValues}
      />
      <Divider />
      <Descriptions
        title="Primary Metadata"
        layout="inline-vertical"
        style={{marginTop: 20, marginBottom: 20}}
        colon=": "
        data={[
          {label: convertFieldToTitle("title"), value: formatMetadata(metadata.title, false, false)},
          {label: convertFieldToTitle("author"), value: formatMetadata(metadata.author, false, false)},
          {label: convertFieldToTitle("creation_date"), value:formatMetadata(metadata.creation_date, false, true)},
          {label: convertFieldToTitle("modification_date"), value: formatMetadata(metadata.modification_date, false, true)},
          {label: convertFieldToTitle("software_creator"), value: formatMetadata(metadata.software_creator, false, false)},
          {label: convertFieldToTitle("software_producer"), value: formatMetadata(metadata.software_producer, false, false)},
        ]}
      />
      {
        Object.keys(metadata.other).length ? 
          <Descriptions
            title="Other Metadata"
            layout="inline-vertical"
            style={{marginTop: 20, marginBottom: 20}}
            colon=": "
            data={constructDescriptionData(metadata.other)}
          />
        :
          <></>
      }
    </>
  );
}