import React, {useState, useCallback } from 'react';
import { Button, Divider, Grid, Form, Message, Input, Select } from '@arco-design/web-react';
import { createWebhook, updateWebhook } from '../../api/webhooks';

export function WebhookForm(props) {
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [eventTrigger, setEventTrigger] = useState(null);

  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;
  
  let initialValues = {url: null, trigger: null};
  if (instanceExists) {
    initialValues.url = instance.url;
    initialValues.trigger = instance.trigger;
    form.setFieldsValue(initialValues);
  }

  const onFormSubmit = useCallback((formData) => {
    setWorking(true);
    if (instanceExists) {
      updateWebhook(instance.uuid, formData.url, formData.trigger).then((response) => {
        if (!response.ok) {
          Message.error("Could not update webhook.");
          setWorking(false);
          return;
        }
        return response.json();
      }).then((json) => {
        setWorking(false);
        props.onUpdateSuccess(json);
      }).catch((error) => {
        Message.error("Could not update webhook.");
        setWorking(false);
      })
    } else {
      createWebhook(formData.url, formData.trigger).then((response) => {
        if (!response.ok) {
          Message.error("Could not create webhook.");
          setWorking(false);
          return;
        }
        return response.json();
        }).then((json) => {
          Message.success("Webhook updated successfully");
          setWorking(false);
          props.onAddSuccess(json);
        }).catch((error) => {
          Message.error("Could not create webhook.");
          setWorking(false);
        })
    }
  }, [props.onAddSuccess, props.onUpdateSuccess]);

  return (
    <Form form={form} onSubmit={(formData) => {onFormSubmit(formData)}} labelAlign="left">
      <Form.Item label='URL' field="url" rules={[{required: true}]}>
        <Input disabled={working} name="url" placeholder='Enter full url (e.g. https://www.webhook.com/my-endpoint)' />
      </Form.Item>
      <Form.Item label="Event" field="trigger" rules={[{required: true}]}>
        <Select disabled={working} options={["DOCUMENT_CHECK_COMPLETION"]} onChange={(value) => {form.setFieldValue("trigger", value)}}/>
      </Form.Item>
      <Divider/>
      <Grid.Row align="end" justify='end'>
        <Form.Item>
          <Button type='primary' loading={working} htmlType='submit'>Submit</Button>
        </Form.Item>
      </Grid.Row>
    </Form>
  );
}