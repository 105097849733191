import React, { useCallback, useState } from "react";
import { Button,AutoComplete, Divider, Grid, Form, Input, Message } from "@arco-design/web-react";
import { IconPlus } from "@arco-design/web-react/icon";
import { createDocumentType, createInstitution, updateInstitution } from "../../api/documents";


export function InstitutionForm(props) {
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);


  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;
  
  let initialValues = {name: null, institution_type: null};
  if (instanceExists) {
    initialValues.name = instance.name;
    initialValues.institution_type = instance.institution_type;
    form.setFieldsValue(initialValues);
  }

  const onFormSubmit = useCallback((formData) => {
    setWorking(true);
    
    let method = instanceExists ? updateInstitution : createInstitution;
    let args = [formData.name, formData.institution_type];
    if (instanceExists) {
      args.unshift(instance.uuid);
    }
    method(...args).then((response) => {
      if (!response.ok) {
        setWorking(false);
        props.onFail(response);
        return;
      }
      return response.json();
    }).then((json) => {
      setWorking(false);
      form.clearFields();
      props.onSuccess(json);
    }).catch((error) => {
      setWorking(false);
      props.onError(error);
    })
  }, [props.onSuccess, props.onError, props.onFail]);

  return (
    <Form form={form} onSubmit={(formData) => {onFormSubmit(formData)}} labelAlign="left">
      <Form.Item label='Name' field="name" rules={[{required: true}]}>
        <Input name="name" placeholder='Name of institution (e.g. Some Company LTD)' />
      </Form.Item>
      <Form.Item label='Type' field="institution_type" rules={[{required: false}]}>
        <AutoComplete
          placeholder='Input `B`'
          data={props.typeOptions}
          onSelect={(value) => {form.setFieldValue("institution_type", value)}}
          onChange={(value) => {form.setFieldValue("institution_type", value)}}
        />
      </Form.Item>
      <Divider/>
      <Grid.Row align="end" style={{justifyContent: "flex-end"}}>
        <Grid.Col md={24} style={{justifyContent: "flex-end"}}>
          <Grid.Row style={{justifyContent: "flex-end"}}>
            <Button loading={working} type="primary" style={{margin: 10}} du htmlType='submit'>Submit</Button>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
}