import React from "react";
import { Modal } from "@arco-design/web-react";
import { DocumentTypeForm } from "../forms/DocumentTypeForm";


export function DocumentTypeModal(props) {

  return (
    <Modal
      visible={props.visible}
      title={<div align="left">Add Document Type</div>}
      footer={null}
      onCancel={props.onCancel}
    >
      <DocumentTypeForm
        onSuccess={props.onFormSuccess}
        onError={props.onFormError}
        onFail={props.onFormFail}
      />
    </Modal>
  );
}