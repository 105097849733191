import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { DatePicker, Typography, Table, Upload, Grid, Button, Form, Input, Select } from '@arco-design/web-react';
import { COUNTRIES } from '../../countries';
import { IconPlus, IconRight } from '@arco-design/web-react/icon';


export function BasicInformationForm(props) {
  const [form] = Form.useForm();

  return (
    <Form form={form} onSubmit={(formData) => {props.handleSubmit(formData)}} labelAlign="left">
      <Form.Item label='First Name' field="first_name" rules={[{required: true}]}>
        <Input name="subject_first_name" placeholder='enter subject first name...' />
      </Form.Item>
      <Form.Item label='Last Name' field="last_name" rules={[{required: true}]}>
        <Input name="subject_last_name" placeholder='enter subject last name...' />
      </Form.Item>
      <Form.Item label='Email' field="email" rules={[{required: true}]}>
        <Input name="subject_email" placeholder='enter subject email...'/>
      </Form.Item>
      <Form.Item label='Date of Birth' field="date_of_birth">
        <DatePicker style={{width: "100%"}} />
      </Form.Item>
      <Grid.Row align="end" style={{justifyContent: "flex-end"}}>
        <Grid.Col md={24} style={{justifyContent: "flex-end"}}>
          <Grid.Row style={{justifyContent: "flex-end"}}>
            <Button style={{margin: 10}}>Cancel</Button>
            <Button type="primary" style={{margin: 10}} htmlType='submit'>Continue <IconRight/></Button>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
}


export function AddressForm(props) {
  const [form] = Form.useForm();
  const [addressResult, setAddressResult] = useState(null);

  return (
    <Form
      form={form}
      onSubmit={(formData) => {
        formData.address = addressResult;
        props.handleSubmit(formData);
        // TODO: clear form on submit
      }}
      labelAlign="left"
      layout="vertical"
    >
      <Grid.Row>
        <Grid.Col md={24}>
          <Form.Item label="Address" field="address" >
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_MAPS_API_KEY}
              selectProps={{
                addressResult,
                onChange: (value) => {setAddressResult(value)}
              }}
            />
          </Form.Item>
        </Grid.Col>
        <Grid.Col md={24}>
          <Grid.Row>
            <Grid.Col md={12} style={{paddingRight: 10}}>
              <Form.Item label="Start Date" field="start_date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="End Date" field="end_date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Grid.Col>
            <Grid.Col md={12} style={{paddingLeft: 10}}>
            <Form.Item label="Supporting Documents" field="supporting_documents">
                <Upload
                  multiple={true}
                  action='/'
                  autoUpload={false}
                  onChange={(fileList, file) => {}}
                >
                  <div className='trigger'>
                    <div>
                      Drag the file here or
                      <span style={{ color: '#3370FF', padding:'0 4px' }} >
                        Click to upload
                      </span>
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={24}>
          <Form.Item align="right"><Button type='primary' loading={props.working} htmlType='submit'>Add Address <IconPlus/></Button></Form.Item>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
}


export function EmploymentHistoryForm(props) {
  const [form] = Form.useForm();
  
  return (
    <Form
    form={form}
    onSubmit={(formData) => {
      props.handleSubmit(formData);
      // TODO: clear form on submit
    }}
    labelAlign="left"
    layout="vertical"
    >
      <Grid.Row>
        <Grid.Col md={5} style={{paddingRight: 10}}>
          {/* TODO: replace with autocomplete */}
          <Form.Item label='Company Name' field="company_name" rules={[{required: true}]}>
            <Input name="company_name" placeholder='company name...' />
          </Form.Item>
        </Grid.Col>
        <Grid.Col md={6} style={{paddingLeft: 10, paddingRight: 10}}>
          <Form.Item label="Start Date" field="start_date">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Grid.Col>
        <Grid.Col md={6} style={{paddingLeft: 10, paddingRight: 10}}>
          <Form.Item label="End Date" field="end_date">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Grid.Col>
        <Grid.Col md={6} style={{paddingLeft: 10}}>
          <Form.Item label="Supporting Documents" field="supporting_documents">
            <Upload
              multiple={true}
              action='/'
              autoUpload={false}
              onChange={(fileList, file) => {}}
            >
              <div className='trigger'>
                <div>
                  Drag the file here or
                  <span style={{ color: '#3370FF', padding:'0 4px' }} >
                  Click to upload
                  </span>
                </div>
              </div>
            </Upload>
          </Form.Item>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={24}>
          <Form.Item align="right"><Button type='primary' loading={props.working} htmlType='submit'>Add Employment <IconPlus/></Button></Form.Item>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
}



export function QuickAddTableSummary(props) {


  return (
    <Table.Summary fixed="bottom">
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={1}>
          <Input name={props.inputName} value={props.inputValue} placeholder={props.placeholder} onChange={props.onInputChange}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <div align="right" style={{marginLeft: 5}}>
            <Button
              type='primary'
              htmlType='submit'
              size="mini"
              style={{width: "100%"}}
              onClick={() => {
                if (props.inputValue === null) {
                  return;
                }
                props.handleSubmit(props.inputValue)
              }}
            >
              {props.buttonText}
            </Button>
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
}



export function AddAddressTableSummary(props) {
  const [addressResult, setAddressResult] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={1}>
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_MAPS_API_KEY}
            selectProps={{
              menuPosition: "fixed",
              styles: {menuList: (provided) => ({...provided, zIndex: 9999})},
              addressResult,
              onChange: (value) => {setAddressResult(value)}
            }}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <DatePicker style={{ width: "100%" }} onSelect={(value) => {setStartDate(value)}}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <DatePicker style={{ width: "100%" }} onSelect={(value) => {setEndDate(value)}}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <Upload
            multiple={true}
            action='/'
            autoUpload={false}
            onChange={(fileList, file) => {
              setDocuments(fileList)
            }}
          >
            <div className='trigger'>
              <div><span style={{ color: '#3370FF' }} >Click to upload</span></div>
            </div>
          </Upload>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <Button
            type='primary'
            htmlType='submit'
            size="mini"
            style={{width: "100%"}}
            onClick={() => {
              props.handleSubmit({
                address: addressResult,
                start_date: startDate,
                end_date: endDate,
                supporting_documents: documents,
              })
            }}
          >
            {props.buttonText}
          </Button>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
}


export function AddEmploymentTableSummary(props) {
  const [companyName, setCompanyName] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={1}>
          <Input placeholder='Enter company name...' onChange={(value) => {setCompanyName(value)}}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <DatePicker style={{ width: "100%" }} onSelect={(value) => {setStartDate(value)}}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <DatePicker style={{ width: "100%" }} onSelect={(value) => {setEndDate(value)}}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <Upload
            multiple={true}
            action='/'
            autoUpload={false}
            onChange={(fileList, file) => {
              setDocuments(fileList)
            }}
          >
            <div className='trigger'>
              <div><span style={{ color: '#3370FF' }} >Click to upload</span></div>
            </div>
          </Upload>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <Button
            type='primary'
            htmlType='submit'
            size="mini"
            style={{width: "100%"}}
            onClick={() => {
              props.handleSubmit({
                company_name: companyName,
                start_date: startDate,
                end_date: endDate,
                supporting_documents: documents,
              })
            }}
          >
            {props.buttonText}
          </Button>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
}

export function AddOtherDocumentTableSummary(props) {
  const [documentType, setDocumentType] = useState(null);
  const [document, setDocument] = useState(null);

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={1}>
          <Input placeholder='Enter document type...' onChange={(value) => {setDocumentType(value)}}/>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <Upload
            multiple={false}
            action='/'
            autoUpload={false}
            onChange={(fileList, file) => {
              setDocument(file)
            }}
          >
            <div className='trigger'>
              <div><span style={{ color: '#3370FF' }} >Click to upload</span></div>
            </div>
          </Upload>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1}>
          <Button
            type='primary'
            htmlType='submit'
            size="mini"
            style={{width: "100%"}}
            onClick={() => {
              props.handleSubmit({
                document_type: documentType,
                document: document,
              })
            }}
          >
            {props.buttonText}
          </Button>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
}