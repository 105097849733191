import React from 'react';
import { Input, Card, Spin, Link, Message, Layout, Button, Result, Grid, Table, Tabs, Form } from "@arco-design/web-react";
import { Graph } from "react-d3-graph";

import { DefaultPageLayout } from './layouts';
import { getAssociations, getNetworkCharacters } from '../../api/network';
import { NetworkGraph } from '../presentational/NetworkGraph';
import { CharacterModal } from '../presentational/CharacterModal';


function getNodeColor(node) {
  if (node.decision == "NOT_FRAUD") {
    return "#7BE188";
  }
  if (node.decision == "FRAUD") {
    return "#F76560";
  }
  if (node.suspicion === 0.0) {
    return "#C3E7FE"
  }
  if (node.suspicion > 0.0 && node.suspicion < 1.0) {
    return "#FFE4BA";
  }
  if (node.suspicion  >= 1.0 && node.suspicion < 2.0) {
    return "#FFCF8B";
  }
  if (node.suspicion  >= 2.0 && node.suspicion < 3.0) {
    return "#FFB65D";
  }
  if (node.suspicion  >= 3.0 && node.suspicion < 4.0) {
    return "#FF9A2E";
  }
  if (node.suspicion  >= 4.0 && node.suspicion < 5.0) {
    return "#FF7D00";
  }
  if (node.suspicion === 5.0) {
    return "#F98981";
  }
}


export default class NetworkScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      selectedCharacter: null,
      showCharacterModal: false,
      characters: [],

      graphNodes: [],
      graphLinks: [],
    }

    this.loadData = this.loadData.bind(this);

    this.onNodeClick = this.onNodeClick.bind(this);
    this.renderCharacterModal = this.renderCharacterModal.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    getNetworkCharacters().then((response) => {
      if (!response.ok) {
        Message.error("could not load characters for network.");
        this.setState({loading: false, error: true})
        return;
      }
      return response.json();
    }).then((json) => {
      for (let i in json) {
        let currentJson = json[i];
        currentJson.id = currentJson.uuid;
        currentJson.label = `${currentJson.first_name} ${currentJson.last_name}`;
        currentJson.color = getNodeColor(currentJson);
      }
      this.setState({graphNodes: json, loading: false});
    });

    getAssociations().then((response) => {
      if (!response.ok) {
        Message.error("Could not load info edges.");
        return;
      }
      return response.json();
    }).then((json) => {
      
      for (let i in json) {
        json[i].from = json[i].source_uuid;
        json[i].to = json[i].target_uuid;
      }

      this.setState({graphLinks: json});
    });
  }

  onNodeClick(selectedNode) {
    const node = selectedNode[0];
    for (let i = 0; i < this.state.graphNodes.length; i++) {
      let currentNode = this.state.graphNodes[i];
      if (currentNode.uuid === node) {
        console.log(currentNode);
        this.setState({selectedCharacter: currentNode, showCharacterModal: true});
        break;
      }
    }
  }

  renderCharacterModal() {
    if (this.state.showCharacterModal) {
      let relevantLinks = [];
      for (let i = 0; i < this.state.graphLinks.length; i++) {
        let currentLink = this.state.graphLinks[i];
        let selectedUUID = this.state.selectedCharacter.uuid;
        if ([currentLink.source_uuid, currentLink.target_uuid].includes(selectedUUID)) {
          let targetUUID = currentLink.source_uuid === selectedUUID ? currentLink.target_uuid : currentLink.source_uuid;
          for (let j = 0; j < this.state.graphNodes.length; j++) {
            if (this.state.graphNodes[j].uuid === targetUUID) {
              currentLink.character = this.state.graphNodes[j];
              break;
            }
          }
          relevantLinks.push(currentLink);
        }
      }
      return <CharacterModal
        character={this.state.selectedCharacter}
        associations={relevantLinks}
        visible={this.state.showCharacterModal}
        onCancel={() => {this.setState({showCharacterModal: false})}}
      />
    }
  }

  renderContent() {

    if (this.state.error) {
      return (
        <Layout.Content>
          <Result
            status='error'
            title='Error message'
            subTitle='Something went wrong. Please try again. '
          />
        </Layout.Content>
      );
    }

    if (this.state.loading) {
      return (
        <Layout.Content>
          <Spin/>
        </Layout.Content>
      );
    }

    return (
      <Layout.Content>
        {this.renderCharacterModal()}
        <Grid.Row style={{justifyContent: "space-between"}}>
          <Grid.Col md={14}>
            <Form>
              <Form.Item field='search'>
                <Input.Search placeholder='search by uuid, name or email...' searchButton={true}/>
              </Form.Item>
            </Form>
          </Grid.Col>
          <Grid.Col md={10} style={{justifyContent: "flex-end"}}>
            <Grid.Row style={{justifyContent: "flex-end"}}>
              {/* <Link href='/documents/add' icon={<IconPlus/>} style={{marginRight: 15}}>Add</Link>
              <Link href='/documents/add' icon={<IconRefresh />} style={{marginRight: 15}}>Refresh</Link>
              <Link href='/documents/add' icon={<IconExport/>} style={{marginRight: 15}}>Export</Link>
              <Link href='/documents/add' icon={<IconFile/>} style={{marginRight: 15}}>Report</Link>
              <Link href='/documents/add' icon={<IconSettings/>}>Settings</Link> */}
            </Grid.Row>
            {/* <Button icon={<IconExport/>}>Export</Button> */}
          </Grid.Col>
        </Grid.Row>
        <Card style={{height: "90%"}}>
          <NetworkGraph
            onNodeClick={this.onNodeClick}
            graphNodes={this.state.graphNodes}
            graphLinks={this.state.graphLinks}
          />
        </Card>
      </Layout.Content>
    );
  }


  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout
      pageHeader="Fraud Network"
    >
      {pageContent}
    </DefaultPageLayout>
  }
}