import React from 'react';
import { Spin, Link, Message, Layout, Button, Input, Grid, Table, Tabs, Form, Empty } from "@arco-design/web-react";
import { DefaultPageLayout } from './layouts';
import { IconExport, IconFileImage, IconSettings, IconFile, IconFilePdf, IconLaunch, IconPlus, IconRefresh, IconTool } from '@arco-design/web-react/icon';
import { listPDFDocuments } from '../../api/documents';
import { VerdictListDisplay } from '../presentational/tableDisplay';
import { converDateStringToReadable } from '../../utils/dates';
import { AddDocumentModal } from '../modals/AddDocumentModal';
import { DocumentDetailModal } from '../modals/documentDetailModal/modal';
import { usePDFDocumentStore } from '../../state/documents';


export default class DocumentsListScreen extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          error: false,
          loading: true,
          currentPage: 1,
          currentResults: [],
          selectedDocument: null,
          showAddDocumentModal: false,
      };

      this.onPDFDocumentPipelineCompletion = this.onPDFDocumentPipelineCompletion.bind(this);
      this.onSetVerdictSuccess = this.onSetVerdictSuccess.bind(this);
      this.renderTable = this.renderTable.bind(this);
      this.renderContent = this.renderContent.bind(this);
      this.renderPDFTabContent = this.renderPDFTabContent.bind(this);
      this.renderImageTabContent = this.renderImageTabContent.bind(this);
  }

  componentDidMount() {
    listPDFDocuments({page: this.state.currentPage}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load documents.");
        this.setState({error: true, loading: false});
        return;
      }
      return response.json();
    }).then((json) => {
      console.log(json);
      this.setState({loading: false, error: false, currentResults: json});
    }).catch((error) => {
      Message.error("Could not load documents.");
      this.setState({error: true, loading: false});
    })
  }

  onPDFDocumentPipelineCompletion(data) {
    let newList = structuredClone(this.state.currentResults);
    newList.push(data.document);
    Message.info("Document pipeline completed");
    this.setState({showAddDocumentModal: false, currentResults: newList});
  }

  onSetVerdictSuccess(data) {
    let newDocuments = [];
    for (let i = 0; i < this.state.currentResults.length; i++) {
      let document = this.state.currentResults[i];
      if (document.uuid === data.uuid) {
        newDocuments.push(data);
      } else {
        newDocuments.push(document);
      }
    }
    this.setState({currentResults: newDocuments});
  }

  renderTable() {
    if (this.state.loading) {
      return <Spin dot />;
    }
    return (
      <>
        <Table
          size="small"
          hover={true}
          pagination={false}
          data={this.state.currentResults}
          border={{
            headerCell: {
              wrapper: true,
              cell: true,
            }
          }}
          columns={[
            {title: "Name", dataIndex: "file_name", render: (_, record) => {return record.file_name}},
            {title: "Uploaded At", dataIndex: "created_at", render: (_, record) => {return converDateStringToReadable(record.created_at)}},
            {title: "Type", dataIndex: "document_type", render: (_, record) => { return record.document_type == null? "-" : record.document_type.toLowerCase().replace(/_/, " "); }},
            {title: "Verdict", dataIndex: "", render: (_, record) => { return <VerdictListDisplay record={record} onSuccess={this.onSetVerdictSuccess}/> }},
            {title: "", align: "right", dataIndex: "", render: (_, record) => {
              return (
                <>
                <Button
                  type="text"
                  iconOnly={true}
                  icon={<IconLaunch style={{color: "#165DFF"}}/>}
                  onClick={() => {this.setState({selectedDocument: record.uuid})}}
                />
                <a href={`/documents/${record.uuid}`}>view</a>
                </>
              );
            }}
          ]}
          rowKey={(record) => {return record.uuid}}
        />
      </>
    );
  }

  renderPDFTabContent() {
    return (
      <Layout.Content>
        <Grid.Row>
          <Grid.Col md={24}>
            <Grid.Row style={{justifyContent: "space-between"}}>
              <Grid.Col md={14}>
                <Form>
                  <Form.Item field='search' label="search">
                    <Input.Search placeholder='search by uuid...' searchButton={true}/>
                  </Form.Item>
                </Form>
              </Grid.Col>
              <Grid.Col md={10} style={{justifyContent: "flex-end"}}>
                <Grid.Row style={{justifyContent: "flex-end"}}>
                  <Link onClick={() => {this.setState({showAddDocumentModal: true})}} icon={<IconPlus/>} style={{marginRight: 15}}>Add</Link>
                  <Link href='#' icon={<IconRefresh />} style={{marginRight: 15}}>Refresh</Link>
                  <Link href='#' icon={<IconExport/>} style={{marginRight: 15}}>Export</Link>
                  <Link href='#' icon={<IconFile/>} style={{marginRight: 15}}>Report</Link>
                  <Link href='/documents/settings' icon={<IconSettings/>}>Settings</Link>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            {this.renderTable()}
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
      )
  }

  renderImageTabContent() {
    return <Empty icon={<IconTool style={{color: '#165DFF'}}/>} description="Image document support coming soon..."/>
  }

  renderContent() {
    return (
      <Layout.Content>
        {this.state.selectedDocument !== null ? <DocumentDetailModal visible={this.state.selectedDocument !== null} uuid={this.state.selectedDocument} onCancel={() => {this.setState({selectedDocument: null})}}/> : <></>}
        <AddDocumentModal visible={this.state.showAddDocumentModal} onCancel={() => {this.setState({showAddDocumentModal: false})}} onSuccess={this.onPDFDocumentPipelineCompletion}/>
        <Tabs defaultActiveTab='1'>
          <Tabs.TabPane key="1" title={<span><IconFilePdf style={{ marginRight: 6 }} />PDF Documents</span>}>
            {this.renderPDFTabContent()}
          </Tabs.TabPane>
          <Tabs.TabPane key="2" title={<span><IconFileImage style={{ marginRight: 6 }} />Image Documents</span>}>
            {this.renderImageTabContent()}
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    )
  }

  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout
      pageHeader="Document Checks"
      pageDescription={"In the documents section, you can find all the documents you have run analysis on and their results. You can view and re-run documents by clicking on the document in the list. You can also refresh the list to see the current status of the documents as they are processed by our pipeline."}
    >
      {pageContent}
    </DefaultPageLayout>
  }
}