import { Grid, Image } from "@arco-design/web-react";

export function SimpleAccountDisplay(props) {
  return (
    <div className="simple-account-display">
      <Grid.Row align="left" style={{padding: 5}}>
        <Grid.Col md={8} align="left">
          <Image
          style={{clipPath: "circle()"}}
            width={60}
            src='./images/placeholderprofile.png'
            alt='profilepicture'
          />
        </Grid.Col>
        <Grid.Col md={16} align="left" style={{paddingLeft: 5}}>
          <h3 style={{marginTop: 0, marginBottom: 0}}>John Doe</h3>
          <p style={{fontSize: 12, marginTop: 5}}>Fraud Master</p>
        </Grid.Col>
      </Grid.Row>
      <hr style={{width: "90%"}}/>
    </div>
  );
}