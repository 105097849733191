
export const REQUEST_TYPES = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});


const BASE_API_URL = `${process.env.REACT_APP_BASE_API_URL}api/dashboard/v1/`;

export const AUTH_URL = `${BASE_API_URL}auth/login`;
export const DOCUMENTS_BASE_URL = `${BASE_API_URL}documents/`;
export const GET_SIGNED_URL_URL_FOR_UPLOAD = `${BASE_API_URL}common/get-signed-url-for-upload`;
export const GET_SIGNED_URL_URL_FOR_RETRIEVE = `${BASE_API_URL}common/get-signed-url-for-retrieve`;
export const PDF_DOCUMENTS_BASE_URL = `${DOCUMENTS_BASE_URL}pdf/`;
export const CREATE_PDF_DOCUMENT_URL = `${DOCUMENTS_BASE_URL}pdf/`;
export const DOCUMENT_TYPES_URL = `${DOCUMENTS_BASE_URL}types/`;
export const INSTITUTIONS_URL = `${DOCUMENTS_BASE_URL}institutions/`;
export const WEBHOOKS_URL = `${BASE_API_URL}webhooks/`;

const BASE_NETWORK_URL = `${BASE_API_URL}network/`;
export const NETWORK_CHECK_INFO_URL = `${BASE_NETWORK_URL}check-suspicious-info`;
export const NETWORK_CHARACTERS_SEARCH_URL = `${BASE_NETWORK_URL}search/characters`;
export const NETWORK_CHARACTERS_DATA_URL = `${BASE_NETWORK_URL}data/characters`;

export const NETWORK_ASSOCIATIONS_URL = `${BASE_NETWORK_URL}search/associations`;

// websockets
export const PDF_PIPELINE_WEBSOCKET_URL = `${process.env.REACT_APP_BASE_WS_URL}pdf_pipeline`;