import React from 'react';
import { DefaultPageLayout } from './layouts';
import { Popconfirm, Button, Grid, Tabs, Form, Input, Link, Layout, Message, Table, Tag } from '@arco-design/web-react';
import { IconCheckCircle, IconCloseCircle, IconMinusCircle, IconPlus, IconSend, IconSearch, IconEdit, IconClose } from '@arco-design/web-react/icon';
import { deleteWebhook, listWebhooks } from '../../api/webhooks';
import { AddWebhookModal } from '../modals/webhooks/AddWebhookModal';
import { WebhookResultsModal } from '../modals/webhooks/WebhookResultsModal';
import { EditWebhookModal } from '../modals/webhooks/EditWebhookModal';


export default class NotificationListScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      webhooks: [],
      selectedWebook: null,
      showAddModal: false,
      showResultsModal: false,
      showEditModal: false,
    }

    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.onAddSuccess = this.onAddSuccess.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    listWebhooks({}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load webhooks");
        this.setState({loading: false, error: true});
      }
      return response.json();
    }).then((json) => {
      this.setState({webhooks: json, loading: false, error: false});
    }).catch((error) => {
      Message.error("Could not load webhooks");
      this.setState({loading: false, error: true});
    })
  }


  onAddSuccess(responseData) {
    let webhooks = structuredClone(this.state.webhooks);
    webhooks.push(responseData);
    this.setState({webhooks: webhooks, showAddModal: false});
  }

  onUpdateSuccess(responseData) {
    let webhooks = structuredClone(this.state.webhooks);
    let newWebhookList = [];
    for (let i = 0; i < webhooks.length; i++) {
      if (webhooks[i].uuid === this.state.selectedWebook.uuid) {
        newWebhookList.push(responseData);
      } else {
        newWebhookList.push(webhooks[i]);
      }
    }
    this.setState({webhooks: newWebhookList, showEditModal: false});
  }


  onConfirmDelete(uuid) {
    deleteWebhook(uuid).then((response) => {
      if (!response.ok) {
        Message.error("Could not delete webhook.");
        return;
      }
      Message.success("Webhook deleted successfully.");
      let webhooks = structuredClone(this.state.webhooks);
      let newWebhookList = [];
      for (let i = 0; i < webhooks.length; i++) {
        if (webhooks[i].uuid !== uuid) {
          newWebhookList.push(webhooks[i]);
        }
      }
      this.setState({webhooks: newWebhookList});
    })
  }


  renderContent() {
    return (
      <Layout.Content>
        <WebhookResultsModal
          visible={this.state.showResultsModal}
          onCancel={() => {this.setState({selectedWebook: null, showResultsModal: false})}}
          webhook={this.state.selectedWebook}
        />
        <EditWebhookModal
          visible={this.state.showEditModal}
          onCancel={() => {this.setState({selectedWebook: null, showEditModal: false})}}
          onSuccess={(responseData) => {
            this.onUpdateSuccess(responseData);
          }}
          instance={this.state.selectedWebook}
        />
        <AddWebhookModal visible={this.state.showAddModal} onSuccess={this.onAddSuccess} onCancel={() => {this.setState({showAddModal: false})}}/>
        <Tabs defaultActiveTab='1'>
          <Tabs.TabPane key="1" title={<span><IconSend style={{ marginRight: 6 }} />Webhooks</span>}>
        <Grid.Row>
          <Grid.Col md={24}>
            <Grid.Row style={{justifyContent: "space-between"}}>
              <Grid.Col md={14}>
                <Form>
                  <Form.Item field='search' label="search">
                    <Input.Search placeholder='search by url...' searchButton={true}/>
                  </Form.Item>
                </Form>
              </Grid.Col>
              <Grid.Col md={10} style={{justifyContent: "flex-end"}}>
                <Grid.Row style={{justifyContent: "flex-end"}}>
                  <Link onClick={() => {this.setState({showAddModal: true})}} icon={<IconPlus/>} style={{marginRight: 15}}>Add</Link>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <Table
              hover={true}
              pagination={false}
              data={this.state.webhooks}
              loading={this.state.loading}
              rowKey="uuid"
              columns={[
                {title: "URL", dataIndex: "url"},
                {title: "Trigger", dataIndex: "trigger"},
                {title: "Latest Event", dataIndex: ".", render: (_, record) => {
                    if (record.results.length === 0) {
                      return <Tag icon={<IconMinusCircle/>}>No Events</Tag>
                    }
                    let latestResult = record.results[record.results.length - 1];
                    if (latestResult.status === "SENT") {
                      return <Tag color="green" style={{marginRight: 5}}><IconCheckCircle/> Success</Tag>
                    }
                    if (latestResult.status === "FAILED") {
                      return <Tag color="red" style={{marginRight: 5}}><IconCloseCircle/> Failed</Tag>
                    }
                  }
                },
                {title: "", align: "right", dataIndex: "", render: (_, record) => {
                  return (
                    <>
                    <Button
                      type='text'
                      iconOnly={true}
                      icon={<IconSearch style={{color: "#165DFF"}}/>}
                      onClick={() => {this.setState({selectedWebook: record, showResultsModal: true})}}
                    />
                    <Button
                      type='text'
                      iconOnly={true}
                      icon={<IconEdit style={{color: "#165DFF"}}/>}
                      onClick={() => {this.setState({selectedWebook: record, showEditModal: true})}}
                    />
                    <Popconfirm
                      focusLock
                      title='Confirm'
                      content='Are you sure you want to delete?'
                      onOk={() => {
                        this.onConfirmDelete(record.uuid);
                      }}
                    >
                      <Button type='text' iconOnly={true} icon={<IconClose style={{color: "#F53F3F"}}/>}/>
                    </Popconfirm>
                    </>
                  );
                }}
              ]}
            />
          </Grid.Col>
        </Grid.Row>
        </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    );
  }

  render() {
    let content = this.renderContent()
    return (
      <DefaultPageLayout
        pageHeader="Notifications"
        pageDescription={"In the notifications section, you can create, configure, and remove notifications for various events in the platform. For example, you can set up webhooks for when a document check has completed. You can also inspect the results to see which notifications were sent when and with what data."}
      >
        {content}
      </DefaultPageLayout>
    );
  }
}