import React from 'react';
import { Navigate } from 'react-router-dom';

import { Tag, Link, Layout, Button, Input, Grid, Table, Tabs, Form } from "@arco-design/web-react";
import { IconPlus, IconRefresh, IconExport, IconFile, IconSettings } from '@arco-design/web-react/icon';
import { DefaultPageLayout } from './layouts';



export default class CharacterCheckListScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: true,
      currentPage: 1,
      currentResults: [],
      selectedPerson: null,
    }

    this.renderTable = this.renderTable.bind(this);
    this.renderContent = this.rendercontent.bind(this);
  }

  renderTable() {
    return (
        <Table
        hover={true}
        pagination={false}
        data={this.state.currentResults}
        border={{
          headerCell: {
            wrapper: true,
            cell: true,
          }
        }}
        columns={[
          {title: "Name", dataIndex: "file_name", render: (_, record) => {return record.file_name}},
          {title: "Created At", dataIndex: "created_at", render: (_, record) => {return converDateStringToReadable(record.created_at)}},
          {title: "Verdict", dataIndex: "", render: (_, record) => { return <Tag color="red">SUSPICIOUS</Tag> }},
          {title: "", align: "right", dataIndex: "", render: (_, record) => {
            return (
              <Button
                type="text"
                iconOnly={true}
                icon={<IconLaunch style={{ color: "#c9cdd4"}}/>}
                onClick={() => {this.setState({selectedDocument: record.uuid})}}
              />)
          }}
        ]}
        rowKey={(record) => {return record.uuid}}
      />
    );
  }

  rendercontent() {
    return (
      <Layout.Content>
        <Grid.Row>
          <Grid.Col md={24}>
            <Grid.Row style={{justifyContent: "space-between"}}>
              <Grid.Col md={14}>
                <Form>
                  <Form.Item field='search'>
                    <Input.Search placeholder='search by uuid, name, or email...' searchButton={true}/>
                  </Form.Item>
                </Form>
              </Grid.Col>
              <Grid.Col md={10} style={{justifyContent: "flex-end"}}>
                <Grid.Row style={{justifyContent: "flex-end"}}>
                  <Link href='/characterChecks/add' icon={<IconPlus/>} style={{marginRight: 15}}>Add</Link>
                  <Link href='#' icon={<IconRefresh />} style={{marginRight: 15}}>Refresh</Link>
                  <Link href='#' icon={<IconExport/>} style={{marginRight: 15}}>Export</Link>
                  <Link href='#' icon={<IconFile/>} style={{marginRight: 15}}>Report</Link>
                  <Link href='#' icon={<IconSettings/>}>Settings</Link>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            {this.renderTable()}
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    );
  }

  render() {
    if (this.state.selectedPerson !== null) {
      
    }
    let pageContent = this.renderContent();
    return (
      <DefaultPageLayout pageHeader="Character Checks">
        {pageContent}
      </DefaultPageLayout>
    );
  }
}