import React from 'react';
import { Tag, Spin, Link, Message,Popconfirm, Layout, Button, Input, Grid, Table, Tabs, Form, Divider } from "@arco-design/web-react";
import { DefaultPageLayout } from '../layouts';
import { IconExport, IconFileImage, IconSettings, IconFile, IconFilePdf, IconLaunch, IconPlus, IconRefresh, IconTags, IconHome, IconSelectAll, IconEdit, IconClose, IconDesktop } from '@arco-design/web-react/icon';
import { DocumentTypeModal } from '../../modals/DocumentTypeModal';
import { deleteDocumentType, deleteInstitution, listDocumentTypes, listInstitutions } from '../../../api/documents';
import { converDateStringToReadable } from '../../../utils/dates';
import { InstitutionModal } from '../../modals/InstitutionModal';
// import { InstitutionsTable } from './institutionsTable';
import { InstitutionsTab } from './institutionsTab';


export default class DocumentCheckSettingsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDocumentTypes: true,
      documentTypes: [],
      loadingInstitutions: true,
      institutions: [],
      analysisRules: [],
      showAddTypeModel: false,
      showAddInstitutionModel: false,
    };

    this.renderDocumentTypesTab = this.renderDocumentTypesTab.bind(this);
    // this.renderInstitutionsTab = this.renderInstitutionsTab.bind(this);
    this.renderRulesTab = this.renderRulesTab.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }


  componentDidMount() {
    listDocumentTypes({page: 1}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load document types");
        this.setState({loadingDocumentTypes: false});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({documentTypes: json, loadingDocumentTypes: false});
    }).catch((error) => {
      Message.error("Could not load document types.");
      this.setState({loadingDocumentTypes: false});
    });

    // listInstitutions({page: 1}).then((response) => {
    //   if (!response.ok) {
    //     Message.error("Could not load institutions");
    //     this.setState({loadingInstitutions: false});
    //     return;
    //   }
    //   return response.json();
    // }).then((json) => {
    //   this.setState({institutions: json, loadingInstitutions: false});
    // }).catch((error) => {
    //   Message.error("Could not load institutions.");
    //   this.setState({loadingInstitutions: false});
    // });
  }


  renderDocumentTypesTab() {
    return (
      <>
        <DocumentTypeModal
          visible={this.state.showAddTypeModel}
          onCancel={() => {this.setState({showAddTypeModel: false})}}
          onFormSuccess={(data) => {
            let currentTypes = structuredClone(this.state.documentTypes);
            currentTypes.push(data);
            Message.success(`${data.name} added successfully`);
            this.setState({documentTypes: currentTypes, showAddTypeModel: false});
          }}
          onFormError={(error) => {
            Message.error("Could not create document type");
          }}
          onFormFail={(response) => {
            Message.error("Could not create document type");
          }}
        />
        <Table
          hover={true}
          loading={this.state.loadingDocumentTypes}
          pagination={false}
          data={this.state.documentTypes}
          columns={[
            {title: "Name", dataIndex: "name"},
            {title: "Sub-Type", dataIndex: "sub_type"},
            {title: "Created At", dataIndex: "created_at", render: (_, record) => {
              return converDateStringToReadable(record.created_at);
            }},
            {title: "", dataIndex: ".", align: "right", render: (_, record) => {
              return <div>
                <Button style={{marginLeft: 5}} iconOnly={true} icon={<IconEdit/>}/>
                <Popconfirm
                  focusLock
                  title='Confirm'
                  content='Are you sure you want to delete?'
                  onOk={() => {
                    deleteDocumentType(record.uuid).then((response) => {
                      if (!response.ok) {
                        Message.error("Could not delete document type");
                        return;
                      } else {
                        let currentDocumentTypes = structuredClone(this.state.documentTypes);
                        let newDocumentTypes = [];
                        for (let i = 0; i < currentDocumentTypes.length; i++) {
                          let documentType = currentDocumentTypes[i];
                          if (documentType.uuid !== record.uuid) {
                            newDocumentTypes.push(documentType);
                          }
                        }
                        Message.success("Document type deleted successfully.");
                        this.setState({documentTypes: newDocumentTypes});
                      }
                    }).catch((error) => {
                      Message.error("Could not delete document type.");
                    });
                  }}
                  onCancel={() => {}}

                >
                  <Button
                    status='danger'
                    style={{marginLeft: 5}}
                    iconOnly={true}
                    icon={<IconClose style={{color: '#F53F3F'}}/>}
                  />
                </Popconfirm>
              </div>
            }},
          ]}
        />
        <Grid.Row style={{marginTop: 10, justifyContent: "flex-end"}}>
          <Button type="primary" onClick={() => {this.setState({showAddTypeModel: true})}}>Add</Button>
        </Grid.Row>
      </>
    );
  }

  // renderInstitutionsTab() {
  //   return (
  //     <>
  //       <InstitutionModal
  //         visible={this.state.showAddInstitutionModel}
  //         onCancel={() => {this.setState({showAddInstitutionModel: false})}}
  //         onFormSuccess={(data) => {
  //           let currentInstitutions = structuredClone(this.state.institutions);
  //           currentInstitutions.push(data);
  //           Message.success(`${data.name} added successfully`);
  //           this.setState({institutions: currentInstitutions, showAddInstitutionModel: false});
  //         }}
  //         onFormError={(error) => {
  //           Message.error("Could not create institution");
  //         }}
  //         onFormFail={(response) => {
  //           Message.error("Could not create institution");
  //         }}
  //       />
  //       <InstitutionsTable
  //         data={this.state.institutions}
  //         loading={this.state.loadingInstitutions}

  //       />
  //       <Grid.Row style={{marginTop: 10, justifyContent: "flex-end"}}>
  //         <Button type="primary" onClick={() => {this.setState({showAddInstitutionModel: true})}}>Add</Button>
  //       </Grid.Row>
  //     </>
  //   )
  // }

  renderRulesTab() {
    return <></>
  }


  renderContent() {
    return (
      <Tabs defaultActiveTab='1'>
        <Tabs.TabPane key="1" title={<span><IconDesktop style={{ marginRight: 6 }} />Software</span>}>
          
        </Tabs.TabPane>
        <Tabs.TabPane key="2" title={<span><IconTags style={{ marginRight: 6 }} />Document Types</span>}>
          {this.renderDocumentTypesTab()}
        </Tabs.TabPane>
        <Tabs.TabPane key="3" title={<span><IconHome style={{ marginRight: 6 }} />Institutions</span>}>
          <InstitutionsTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" title={<span><IconSelectAll style={{ marginRight: 6 }} />Analysis Rules</span>}>
          {this.renderRulesTab()}
        </Tabs.TabPane>
      </Tabs>
    )
  }

  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout
      pageHeader="Document Check Settings"
      pageDescription={"In the settings section, you can configure check rules and supporting data for your document checks."}
    >
      {pageContent}
    </DefaultPageLayout>
  }
}