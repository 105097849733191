// import { Graph } from "react-d3-graph";
import Graph from "react-vis-network-graph";



export function NetworkGraph(props) {

  const graph = {
    nodes: props.graphNodes,
    edges: props.graphLinks,
  }

  const options = {
    layout: {
      hierarchical: false
    },
    nodes: {
      borderWidth: 1,
      shape: "dot",
      size: 12
    },
    edges: {
      color: "#000000"
    },
  };

  const events = {
    select: function(event) {
      var { nodes, edges } = event;
      props.onNodeClick(nodes);
    },
  };
  return (
    <div style={{backgroundColor: "#f2f3f5", height: "78vh", borderRadius: 1, borderColor: "#e5e6eb"}}>
      <Graph
        graph={graph}
        options={options}
        events={events}
      />
    </div>
  );

}