import { apiDeleteRequest, apiGetRequest, apiPatchRequest, apiPostRequest } from "./base";
import { PDF_DOCUMENTS_BASE_URL, CREATE_PDF_DOCUMENT_URL, GET_SIGNED_URL_URL_FOR_UPLOAD, GET_SIGNED_URL_URL_FOR_RETRIEVE, DOCUMENT_TYPES_URL, INSTITUTIONS_URL } from "./conf";


export async function listPDFDocuments(params) {
  return apiGetRequest(PDF_DOCUMENTS_BASE_URL, params);
}

export async function getSignedURLForUpload(fileName, fileType) {
  return apiGetRequest(GET_SIGNED_URL_URL_FOR_UPLOAD, {"file_type": fileType, "file_name": fileName});
}

export async function getSignedURLForRetrieve(uuid) {
  return apiGetRequest(GET_SIGNED_URL_URL_FOR_RETRIEVE, {"document_uuid": uuid});
}

export async function createPDFDocument(
    subjectFirstName,
    subjectLastName,
    subjectEmail,
    fileName,
    filePath,
    runBackground,
    documentType,
  ) {
  let data = {
    "subject_first_name": subjectFirstName,
    "subject_last_name": subjectLastName,
    "subject_email": subjectEmail,
    "file_name": fileName,
    "file_path": filePath,
    "run_background": runBackground,
  };
  if (documentType !== null) {
    data["document_type"] = documentType;
  }
  return apiPostRequest(CREATE_PDF_DOCUMENT_URL, data);
}


export async function retrievePDFDocument(uuid) {
  return apiGetRequest(`${PDF_DOCUMENTS_BASE_URL}${uuid}`);
}

export function createDocumentType(name, sub_type) {
  let data = {name: name};
  if (sub_type !== null && sub_type !== undefined) {
    data.sub_type = sub_type;
  }

  return apiPostRequest(DOCUMENT_TYPES_URL, data);
}


export function listDocumentTypes(params) {
  return apiGetRequest(DOCUMENT_TYPES_URL, params);
}

export function deleteDocumentType(uuid) {
  return apiDeleteRequest(`${DOCUMENT_TYPES_URL}/${uuid}/`);
}

export function createInstitution(name, institution_type) {
  let data = {name: name};
  if (institution_type !== null && institution_type !== undefined) {
    data.institution_type = institution_type;
  }

  return apiPostRequest(INSTITUTIONS_URL, data);
}


export function updateInstitution(uuid, name, institution_type) {
  let data = {name: name};
  if (institution_type !== null && institution_type !== undefined) {
    data.institution_type = institution_type;
  }

  return apiPatchRequest(`${INSTITUTIONS_URL}${uuid}`, data);
}


export function listInstitutions(params) {
  return apiGetRequest(INSTITUTIONS_URL, params);
}

export function deleteInstitution(uuid) {
  return apiDeleteRequest(`${INSTITUTIONS_URL}${uuid}/`);
}


export function setPDFVerdict(uuid, verdict) {
  return apiPatchRequest(`${PDF_DOCUMENTS_BASE_URL}${uuid}/verdict`, {"verdict": verdict});
}