import { Descriptions, Tabs } from "@arco-design/web-react";
import { ResultTag } from "./presentational";
import React from "react";
import { IconSelectAll } from "@arco-design/web-react/icon";


export function OverallTab(props) {
  return (
    <Descriptions
      colon=": "
      data={[
        {label: "Overall (Auto)", value: <ResultTag result={props.document.overall_check_result.auto_result}/>},
        {label: "Overall (Manual)", value: <ResultTag result={props.document.overall_check_result.manual_result}/>},
        {label: "Metadata", value: <ResultTag result={props.document.metadata_check.result}/>},
        {label: "Edit History", value: <ResultTag result={props.document.version_check.result}/>},
        {label: "Text Content", value: <ResultTag result={props.document.text_check.result}/>},
        {label: "Images", value: <ResultTag result={props.document.image_check.result}/>},
      ]}
    />
  );
}