import { apiGetRequest, apiPostRequest } from "./base";
import {
  NETWORK_CHECK_INFO_URL,
  NETWORK_CHARACTERS_SEARCH_URL,
  NETWORK_CHARACTERS_DATA_URL,
  NETWORK_ASSOCIATIONS_URL,
} from "./conf";


export function preCheckBasicInformation(firstName, lastName, dateOfBirth) {
  return apiGetRequest(
    NETWORK_CHECK_INFO_URL,
    {"first_name": firstName, "last_name": lastName, "date_of_birth": dateOfBirth}
  );
}

export function addCharacter(firstName, lastName, dateOfBirth, email) {
  let data = {
      "first_name": firstName,
      "last_name": lastName,
      "date_of_birth": dateOfBirth,
      "email": email
    };
  console.log(data);
  return apiPostRequest(NETWORK_CHARACTERS_DATA_URL, data);
}

export function getNetworkCharacters() {
  return apiGetRequest(NETWORK_CHARACTERS_SEARCH_URL, {});
}

// export function getBasicInfoEdges() {
//   return apiGetRequest(NETWORK_BASIC_EDGES_URL, {});
// }


export function getAssociations() {
  return apiGetRequest(NETWORK_ASSOCIATIONS_URL, {});
}