import { Tag } from "@arco-design/web-react";
import { IconCheckCircle, IconCloseCircle, IconExclamationCircle, IconMinusCircle, IconMore, IconQuestionCircle } from "@arco-design/web-react/icon";

export function ResultTag(props) {
  let icon;
  let color;
  if (typeof props.result === "boolean") {
    return <Tag
      color={props.result ? "green": "red"}
      icon={props.result ? <IconCheckCircle/> : <IconCloseCircle/>}
    >
      {props.result ? "YES" : "NO"}
    </Tag>
  }
  switch (props.result) {
    case "PASSED":
      icon = <IconCheckCircle/>;
      color = "green";
      break;
    case "CLEAR":
        icon = <IconCheckCircle/>;
        color = "green";
        break;
    case "FAILED":
      icon = <IconExclamationCircle/>;
      color = "orange";
      break;
    case "SUSPICIOUS":
        icon = <IconExclamationCircle/>;
        color = "red";
        break;
    case "ERROR":
      icon = <IconCloseCircle/>;
      color = "red";
      break;
    case "NOT_APPLICABLE":
      icon = <IconMinusCircle/>;
      color = "gray";
      break;
    case "PENDING":
      icon = <IconMore/>
      color = "blue"
      break;
    case "UNCERTAIN":
      icon = <IconQuestionCircle/>;
      color = "purple";
      break;
    default:
      return <Tag>NOT ON RECORD</Tag>
  }
  return <Tag color={color} icon={icon}>{props.result.replace(/_/g, " ")}</Tag>
}