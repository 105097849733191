import { Descriptions } from "@arco-design/web-react";


export function SummaryTab(props) {
  
  return (
    <>
      <Descriptions
        layout="inline-horizontal"
        title="Document Check Data"
        column={3}
        style={{marginTop: 20, marginBottom: 20}}
        colon=": "
        data={[
          {label: "File", value: props.document.file_name},
          {label: "Document Type", value: props.document_type},
          {label: "Institution", value: props.institution},
          {label: "First Name", value: props.document.subject.first_name},
          {label: "Last Name", value: props.document.subject.last_name},
          {label: "Email", value: props.document.subject.email},
        ]}
      />
      <Descriptions
        layout="inline-vertical"
        title="Check Results"
        style={{marginTop: 20, marginBottom: 20}}
        colon=": "
      />
    </>
  );
}