import LoginScreen from './components/screens/LoginScreen';
import enUS from '@arco-design/web-react/es/locale/en-US';
import DocumentsListScreen from './components/screens/DocumentsListScreen';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from '@arco-design/web-react';
import './App.css';
import "@arco-design/web-react/dist/css/arco.css";
import CharacterCheckListScreen from './components/screens/CharacterCheckListScreen';
import CharacterCheckAddScreen from './components/screens/CharacterCheckAddScreen';
import NetworkScreen from './components/screens/NetworkScreen';
import DocumentCheckSettingsScreen from './components/screens/documentSettings/DocumentCheckSettingsScreen';
import { HomePage } from './components/screens/Home';
import NotificationListScreen from './components/screens/NotificationListScreen';
import DashboardScreen from './components/screens/DashboardScreen';
import DocumentDetailScreen from './components/screens/documentDetail/DocumentDetailScreen';


function App() {
  return (
    <ConfigProvider locale={enUS}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path='/' element={<HomePage/>}/>
            <Route exact path='/dashboard' element={<DashboardScreen/>}/>
            <Route exact path='/login' element={<LoginScreen/>}/>
            <Route exact path='/documents' element={<DocumentsListScreen/>}/>
            <Route exact path='/documents/:uuid' element={<DocumentDetailScreen/>}/>
            <Route exact path='/characterChecks' element={<CharacterCheckListScreen/>}/>
            <Route exact path='/characterChecks/add/' element={<CharacterCheckAddScreen/>}/>
            <Route exact path='/documents/settings' element={<DocumentCheckSettingsScreen/>}/>
            <Route exact path='/network' element={<NetworkScreen/>}/>
            <Route exact path='/webhooks' element={<NotificationListScreen/>}/>
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
