import { Button, Grid, Card, DatePicker, Select, Statistic } from '@arco-design/web-react';
import { BarChart, Bar, Cell, XAxis, YAxis, Pie, PieChart, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import { IconRefresh } from '@arco-design/web-react/icon';
import React from 'react';
import { DefaultPageLayout } from './layouts';


export default class DashboardScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderContent = this.renderContent.bind(this);
  }


  renderContent() {
    const checkData = [
      { name: 'PASSED', value: 400, color: "#00B42A"},
      { name: 'FAILED', value: 300, color: "#FF7D00"},
      { name: 'UNCERTAIN', value: 300, color: "#722ED1"},
      { name: 'NOT_APPLICABLE', value: 200, color: "#86909c"},
      { name: 'PENDING', value: 300, color: "#165DFF"},
      { name: 'ERROR', value: 200, color: "#F53F3F"},
    ];

    const data = [
      {
        name: '01/01/2023',
        pending: 5,
        clear: 2,
        suspicious: 3,
        uncertain: 1,
      },
      {
        name: '02/01/2023',
        pending: 2,
        clear: 4,
        suspicious: 3,
        uncertain: 1,
      },
      {
        name: '03/01/2023',
        pending: 5,
        clear: 4,
        suspicious: 3,
        uncertain: 2,
      },
      {
        name: '04/01/2023',
        pending: 3,
        clear: 5,
        suspicious: 4,
        uncertain: 2,
      },
      {
        name: '05/01/2023',
        pending: 2,
        clear: 6,
        suspicious: 5,
        uncertain: 4,
      },
      {
        name: '06/01/2023',
        pending: 4,
        clear: 7,
        suspicious: 6,
        uncertain: 3,
      },
      {
        name: '07/01/2023',
        pending: 5,
        clear: 10,
        suspicious: 8,
        uncertain: 4,
      },
      {
        name: '08/01/2023',
        pending: 2,
        clear: 6,
        suspicious: 5,
        uncertain: 4,
      },
      {
        name: '09/01/2023',
        pending: 4,
        clear: 7,
        suspicious: 6,
        uncertain: 3,
      },
      {
        name: '010/01/2023',
        pending: 5,
        clear: 10,
        suspicious: 8,
        uncertain: 4,
      },
    ];
    return (
      <>
      <Grid.Row>
        <Grid.Col md={24}>
          <Card title="Document Verdicts by Date">
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              // width={500}
              // height={300}
              data={data}
              margin={{top: 20, right: 30, left: 20, bottom: 5}}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip cursor={false}/>
              <Legend />
              <Bar dataKey="pending" stackId="a" fill="#6AA1FF" />
              <Bar dataKey="clear" stackId="a" fill="#4CD263" />
              <Bar dataKey="suspicious" stackId="a" fill="#FFB65D" />
              <Bar dataKey="uncertain" stackId="a" fill="#c9cdd4" />
            </BarChart>
          </ResponsiveContainer>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={24}>
          <Grid.Row>
            <Grid.Col md={6} align="center">
              <p>metadata_check</p>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width="100%" height={400}>
                  <Pie
                    data={checkData}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {checkData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid.Col>
            <Grid.Col md={6} align="center">
              <p>text_check</p>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width="100%" height={400}>
                  <Pie
                    data={checkData}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {checkData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid.Col>
            <Grid.Col md={6} align="center">
              <p>version_check</p>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width="100%" height={400}>
                  <Pie
                    data={checkData}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {checkData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid.Col>
            <Grid.Col md={6} align="center">
              <p>image_check</p>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart width="100%" height={400}>
                  <Pie
                    data={checkData}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {checkData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
          <Grid.Col md={24} style={{backgroundColor: "red"}}>
            <Legend
              width={"100%"}
              payload={
                [
                  {name: 'Passed', color: "#00B42A"},
                  {name: 'Failed', color: "#FF7D00"},
                  {name: 'Uncertain', color: "#722ED1"},
                  {name: 'Not Applicable', color: "#86909c"},
                  {name: 'Pending', color: "#165DFF"},
                  {name: 'Error', color: "#F53F3F"},
                ].map(
                  (item, index) => ({
                    id: item.name,
                    type: "square",
                    value: item.name,
                    color: item.color,
                  })
                )
              }
            />
          </Grid.Col>
        </Grid.Row>
      </>
    );
  }


  render() {
    return (
      <DefaultPageLayout pageHeader="Dashboard" pageDescription={<><DatePicker.RangePicker size="mini"/> <Button size='mini' iconOnly={true} icon={<IconRefresh/>}/></>}>
        {this.renderContent()}
      </DefaultPageLayout>
    );
  }
}