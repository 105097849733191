import { Descriptions, Modal, Table, Tag } from '@arco-design/web-react';
import { IconCheckCircle, IconCloseCircle, IconSettings } from '@arco-design/web-react/icon';
import React from 'react';
import { converDateStringToReadable } from '../../../utils/dates';


function WebhookResultTag(props) {
  if (props.status === "SENT") {
    return <Tag color="green" style={{marginRight: 5}}><IconCheckCircle/> Success</Tag>
  }
  if (props.status === "FAILED") {
    return <Tag color="red" style={{marginRight: 5}}><IconCloseCircle/> Failed</Tag>
  }
}

function PayloadDisplay(props) {
  if (props.payload === null) {
    return
  }

  let data = [];
  for (const key in props.payload) {
    data.push({label: key, value: props.payload[key]});
  }

  return (
    <Descriptions
      size="mini"
      layout="horizontal-vertical"
      column={1}
      colon=': '
      data={data}
    />
  );
}

export function WebhookResultsModal(props) {
  let results = props.webhook !== null ? props.webhook.results : [];
  return (
    <Modal visible={props.visible} onOk={props.onCancel} onCancel={props.onCancel} title="Webhook Results" style={{width: "60%"}}>
        <Table
          size="small"
          pagination={false}
          data={results}
          columns={[
            {title: "Status", dataIndex: ".", render: (_, record) => {return <WebhookResultTag status={record.status}/>}},
            {title: "Timestamp", dataIndex: "sent_at", render: (_, record) => {return converDateStringToReadable(record.sent_at)}},
            {title: "Response", dataIndex: "response_status"},
            {title: "Payload", dataIndex: "payload", render: (_, record) => <PayloadDisplay payload={record.payload}/>},
          ]}
        />
    </Modal>
  );
}