import React from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Message, Form, Input, Grid, Card, Spin} from "@arco-design/web-react";
import { REQUEST_TYPES, AUTH_URL } from '../../api/conf';
import { IconLoading } from '@arco-design/web-react/icon';


export default class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            working: false,
            success: false,
        };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    async handleSubmit() {
        let formData = new FormData();
        formData.append("username", this.state.username);
        formData.append("password", this.state.password);
        this.setState({working: true});
        fetch(
          AUTH_URL,
          {
            method: REQUEST_TYPES.POST,
            body: formData,
          }
        ).then((response) => {
          if (!response.ok) {
            Message.error("Could not login with provided credentials");
            this.setState({working: false});
            return;
          } else {
            return response.json();
          }
        }).then((json) => {
          localStorage.setItem('DIV_ACCESS_TOKEN', json["access_token"]);
          localStorage.setItem('DIV_REFRESH_TOKEN', json["refresh_token"]);
          Message.success("Login successful.");
          this.setState({working: false, success: true});
        });
    }
    
  renderForm() {
    if (this.state.success) {
      return <Navigate to="/documents" replace={true} />
    }
    if (this.state.loading) {
      return <Grid.Row style={{alignItems: "center", justifyContent: "center"}}> <div><Spin dot /></div></Grid.Row>
    }
    return (
      <Form layout="horizontal" onSubmit={(values) => {this.handleSubmit(values)}}>
        <Form.Item label='Email' field='username' rules={[{ required: true }]}>
          <Input type="email" placeholder="Please enter your email" onChange={(text) => {this.setState({username: text});}}/>
        </Form.Item>
        <Form.Item label='Password' field='password' rules={[{ required: true }]}>
          <Input type="password" placeholder="Please enter your password" onChange={(text) => {this.setState({password: text});}}/>
        </Form.Item>
        <div align="right">
          <Form.Item style={{justifyContent: "end"}}>
            <Button type='primary' htmlType='submit' disabled={this.state.working} icon={this.state.working ? <IconLoading/> : null}>Submit</Button>
          </Form.Item>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div>
        <Grid.Row style={{justifyContent: "center"}}>
          <Grid.Col span={12} style={{alignItems: "center"}}>
            <Card title="Login" style={{ margin: 20 }}>
              {this.renderForm()}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </div>
    )
  }
}