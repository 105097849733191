import { Divider, Empty, Tabs, Image, Grid, Descriptions } from "@arco-design/web-react";
import { ResultTag } from "./presentational";
import { constructDescriptionData } from "./utils";






function ImageDisplay(props) {
  return (
    <Grid.Row>
      <Grid.Col md={8}>
        <Image
          width={200}
          src={props.image.url}
        />
      </Grid.Col>
      <Grid.Col md={16}>
        <Tabs defaultActiveTab="1">
          <Tabs.TabPane key="1" title="Image Data">
            <Descriptions
              title="Image Data"
              colon=": "
              data={[
                {label: "XRef", value: props.image.xref},
                {label: "S-Mask", value: props.image.smask},
                {label: "Width", value: props.image.width},
                {label: "Height", value: props.image.height},
                {label: "BPC", value: props.image.bpc},
                {label: "Extension", value: props.image.extension},
                {label: "Colorspace", value: props.image.colorspace},
                {label: "Alternative Colorspace", value: props.image.alt_colorspace},
                {label: "Name", value: props.image.name},
                {label: "Filter", value: props.image.img_filter},
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" title="EXIF Data">
            {
              Object.keys(props.image.exif_data).length !== 0 ?
                <Descriptions
                  title="EXIF Data"
                  colon=": "
                  data={constructDescriptionData(props.image.exif_data)}
                />
              : 
                <Empty/>
              }
            
          </Tabs.TabPane>
        </Tabs>
      </Grid.Col>
      <Divider/>
    </Grid.Row>
  );
}


export function ImagesTab(props) {
  let images = [];
  for (let i = 0; i < props.document.image_extraction.images.length; i++) {
    images.push(<ImageDisplay image={props.document.image_extraction.images[i]} />);
  }
  return (
    <Grid.Row>
      <Grid.Col md={24} style={{marginBottom: 20}}>
        <h3>Result: <ResultTag result={props.document.image_check.result}/></h3>
      </Grid.Col>
      <Grid.Col md={24}>
        {images}
      </Grid.Col>
    </Grid.Row>
  );
}