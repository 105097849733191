import { Tag } from "@arco-design/web-react"


export function NetworkDecisionTag(props) {
  let decisionDisplay = props.decision.replace(/_/g, " ");
  switch(props.decision) {
    case "FRAUD":
      return <Tag color="#F53F3F">{decisionDisplay}</Tag>
    case "NOT_FRAUD":
      return <Tag color="#00B42A">{decisionDisplay}</Tag>
    case "UNDECIDED":
      return <Tag color="#94BFFF">{decisionDisplay}</Tag>
    default:
      return <Tag>-</Tag>
  }
}