import { Grid, Progress, Switch, Table } from "@arco-design/web-react";
import React from "react";
import { ResultTag } from "./presentational";



export function TextContentTab(props) {

  const textCheck = props.document.text_check;
  const subject = props.document.subject;
  console.log(props.document);
  return (
    <Grid.Row>
      <Grid.Col md={24}>
        <h3>Result: <ResultTag result={props.document.text_check.result}/></h3>
      </Grid.Col>
      <h4>Content</h4>
      <Grid.Col md={24}>
        <Table
          size="small"
          pagination={false}
          columns={[
            {title: "Content Type", dataIndex: "content_type"},
            {title: "Content Value", dataIndex: "content_value"},
            {title: "Found In Document", dataIndex: ".", render: (_, record) => {return <ResultTag result={textCheck.name_found}/>}},
            {title: "Confidence", colSpan: 2, align: "right", dataIndex: ".", render: (_, record) => {return `${textCheck.name_confidence}%`}},
          ]}
          data={[
            {content_type: "Name", content_value: `${subject.first_name} ${subject.last_name}`}
          ]}
        />
        <br/>
        <h4>Fonts</h4>
        <Table
          size="small"
          pagination={false}
          columns={[
            {title: "Base Font", dataIndex: "base_font"},
            {title: "Font", dataIndex: "name"},
            {title: "Type", dataIndex: "font_type"},
            {title: "Encoding", dataIndex: "encoding"},
            {title: "Expected", align: "right", dataIndex: ".", render: (_, record) => {
              return <Switch/>
            }}
          ]}
          data={props.document.text_extraction.extracted_fonts}
        />
      </Grid.Col>
    </Grid.Row>
  );
}