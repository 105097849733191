import { apiDeleteRequest, apiGetRequest, apiPatchRequest, apiPostRequest } from "./base";
import { WEBHOOKS_URL } from "./conf";


export async function listWebhooks(params) {
  return apiGetRequest(WEBHOOKS_URL, params);
}


export async function createWebhook(url, trigger) {
  let data = {
    "url": url,
    "trigger": trigger,
  }
  return apiPostRequest(WEBHOOKS_URL, data);
}

export async function updateWebhook(uuid, url, trigger) {
  let data = {
    "url": url,
    "trigger": trigger,
  }
  return apiPatchRequest(`${WEBHOOKS_URL}${uuid}`, data);
}

export async function deleteWebhook(uuid) {
  return apiDeleteRequest(`${WEBHOOKS_URL}${uuid}`);
}