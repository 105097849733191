import { Modal, Table, Tabs } from '@arco-design/web-react';
import React from 'react';
import { WebhookForm } from '../../forms/WebhookForm';


export function AddWebhookModal(props) {

  return (
    <Modal
      title="Add Webhook"
      visible={props.visible}
      onCancel={props.onCancel}
      footer={null}
    >
      <WebhookForm
        handleSubmit={props.handleSubmit}
        onAddSuccess={props.onSuccess}
      />
    </Modal>
  )
}