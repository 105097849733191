import { Modal } from "@arco-design/web-react";
import { InstitutionForm } from "../../forms/InstitutionForm";



export function InstitutionModal(props) {

  return (
    <Modal
      title={<div align="left">Add Issuing Institution</div>}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={null}
    >
      <InstitutionForm
        instance={props.instance}
        onSuccess={props.onFormSuccess}
        onError={props.onFormError}
        onFail={props.onFormFail}
        typeOptions={props.typeOptions}
      />
    </Modal>
  );
}