import { useState } from "react";
import { Popconfirm, Select, Popover, Button, Tag, Message } from "@arco-design/web-react";
import {IconLoading, IconCheck, IconInfoCircle, IconRobot, IconUser, IconMinus, IconLocation, IconMore, IconWifi, IconHome, IconCheckCircle, IconCodeBlock, IconEmail, IconClose, IconExclamation } from '@arco-design/web-react/icon';
import { converDateStringToReadable } from "../../utils/dates";
import { setPDFVerdict } from "../../api/documents";
import PopoverComponent from "@arco-design/web-react/es/Popover";


export function DocumentStatusDisplay(props) {
  switch (props.status) {
    case "PENDING":
      return <IconMore />
    case "IN_PROGRESS":
      return <IconLoading style={{color: "#3491FA"}}/>
    case "COMPLETE":
      return <IconCheck style={{color: "#00B42A"}}/>
    case "FAILED":
      return <IconClose style={{color: "#F53F3F"}}/>
    default:
      return <IconMinus />
  }
}


export function DocumentMetadataStatusDisplay(props) {
  if (props.metadata !== null && Object.keys(props.metadata).length !== 0) {
    return <IconCheck style={{color: "#00B42A"}}/>
  }
  return <IconClose style={{color: "#F53F3F"}}/>
}


export function DocumentVersionsStatusDisplay(props) {
  if (props.versions !== null) {
    return <IconClose style={{color: "#F53F3F"}}/>
  }
  let numberOfVersions = props.versions.length;
  if (props.versions !== null && numberOfVersions !== 0) {
    return <><IconCheck style={{color: "#00B42A"}}/> ({numberOfVersions})</>
  }
  return <IconClose style={{color: "#F53F3F"}}/>
}


export function dateRangeDisplay(start, end) {
  let startProvided = [null, undefined].includes(start);
  let endProvided = [null, undefined].includes(end);

  let startString = startProvided ? converDateStringToReadable(start) : "-";
  let endString = startProvided ? converDateStringToReadable(end) : "-";

  if (!startProvided && !endProvided) {
    return "-";
  }

  return `${startString} to ${endString}`;

}


export function AssociationsDisplay(props) {

  let tags = [];

  for (let i = 0; i < props.associations.length; i++) {
    switch (props.associations[i]) {
      case "BASIC_INFO":
        tags.push(<Tag style={{marginRight: 5}}><IconInfoCircle style={{color: "#165DFF"}}/> {props.associations[i].replace("_", " ")}</Tag>)
        break
      case "EMAIL":
        tags.push(<Tag style={{marginRight: 5}}><IconEmail style={{color: "#165DFF"}}/> {props.associations[i].replace("_", " ")}</Tag>)
        break
      case "PHYSICAL_ADDRESS":
        tags.push(<Tag style={{marginRight: 5}}><IconLocation style={{color: "#165DFF"}}/> {props.associations[i].replace("_", " ")}</Tag>)
        break
      case "IP_ADDRESS":
        tags.push(<Tag style={{marginRight: 5}}><IconCodeBlock style={{color: "#165DFF"}}/> {props.associations[i].replace("_", " ")}</Tag>)
        break
      case "DOMAIN_NAME":
        tags.push(<Tag style={{marginRight: 5}}><IconWifi style={{color: "#165DFF"}}/> {props.associations[i].replace("_", " ")}</Tag>)
        break
      case "COMPANY":
        tags.push(<Tag style={{marginRight: 5}}><IconHome style={{color: "#165DFF"}}/> {props.associations[i].replace("_", " ")}</Tag>)
        break
    }
  }

  return <spam>{tags}</spam>

}



export function AssociationDataDisplay(props) {

}


export function DocumentList(props) {
  if (props.documents === null || props.documents === undefined) {
    return "-";
  }
  let names = [];
  for (let i = 0; i < props.documents.length; i++) {
    names.push(<li>{props.documents[i].originFile.name}</li>)
  }
  return (
    <ul>{names}</ul>
  );
}





export function ExtractionPipelineListDisplay(props) {
  let statuses = [
    props.record.metadata_extraction.status,
    props.record.image_extraction.status,
    props.record.version_extraction.status,
    props.record.text_extraction.status,
    props.record.json_extraction.status,
  ];

  let icon = <IconMore style={{color: '#a9aeb8', fontSize: 20}}/>;
  let content = "All pipelines pending";

  if (statuses.every(val => val === "FAILED")) {
    content="All pipelines failed";
    icon = <IconClose style={{color: '#F53F3F', fontSize: 20}}/>
  }
  if (statuses.every(val => val === "COMPLETE")) {
    content="All pipelines complete";
    icon = <IconCheckCircle style={{color: '#00B42A', fontSize: 20}}/>
  }
  if (statuses.includes("FAILED")) {
    content="One or more pipelines have failed";
    icon = <IconExclamation style={{color: '#FF7D00', fontSize: 20}}/>;
  }
  if (statuses.includes("COMPLETE") && statuses.includes("PENDING")) {
    content="Some pipelines still pending";
    icon = <IconCheck style={{color: '#AFF0B5', fontSize: 20}}/>;
  }
  return <Popover content={<span>{content}</span>}>{icon}</Popover>
}


export function VerdictListDisplay(props) {

  const [verdict, setVerdict] = useState(null);

  let autoResult = props.record.overall_check_result.auto_result;
  let manualResult = props.record.overall_check_result.manual_result;
  let usedResult = manualResult !== null ? manualResult : autoResult;

  let iconColor;
  let tagColor;
  switch(usedResult) {
    case "PENDING":
      iconColor = "#165DFF";
      tagColor = "blue";
      break;
    case "CLEAR":
      iconColor = "#00B42A";
      tagColor = "green";
      break;
    case "SUSPICIOUS":
      iconColor = "#F53F3F";
      tagColor = "red";
      break;
    case "UNCERTAIN":
      iconColor = "#722ED1"
      tagColor = "purple";
      break;
  }
  
  let icon = manualResult !== null ? <IconUser style={{marginRight: 5}}/> : <IconRobot style={{marginRight: 5}}/>

  return (
    <Popconfirm
      title="Change Verdict"
      content={
        <div>
          <Select
            defaultValue={usedResult}
            options={[
              "CLEAR",
              "SUSPICIOUS",
              "UNCERTAIN",
            ]}
            onChange={(value) => {setVerdict(value)}}
          />
        </div>
      }
      okText="save"
      onOk={() => {
        if (verdict === null) {
          return;
        }
        setPDFVerdict(props.record.uuid, verdict).then((response) => {
          if (!response.ok) {
            Message.error("Could not update verdict.");
            return;
          }
          return response.json();
        }).then((json) => {
          props.onSuccess(json);
        }).catch((error) => {
          Message.error("Could not update verdict.");
          return;
        });
      }}
    >
      <Tag color={tagColor} style={{color: iconColor, cursor: "pointer"}}>{icon}{usedResult}</Tag>
    </Popconfirm>
  )

}



export function DeletePopover(props) {
  return (
    <Popconfirm
      focusLock
      title={props.title}
      content={props.content}
      onOk={props.onOk}
      onCancel={props.onCancel}
    >
      <Button
        status='danger'
        style={props.buttonStyle}
        iconOnly={true}
        icon={<IconClose style={{color: '#F53F3F'}}/>}
      />
    </Popconfirm>
  );
}